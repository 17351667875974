import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { testSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import { getAllHospitals } from "../../../redux/hospital/actions";
import { getAllCategorys } from "../../../redux/category/actions";
import { getAllSampleTypes } from "../../../redux/sampletype/actions";
import { toast } from 'react-toast'
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import Breadcrumb from "../../../components/Breadcrumb";
import { EditorState } from 'draft-js';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MasterServices from '../../../ApiServices/MasterServices'
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Editor } from '@tinymce/tinymce-react';
import ReferenceRangeAddMultiple from "./ReferenceRangeAddMultiple";


let symbols = [
  {
    name: "ADD",
    symbol: "+"
  },
  {
    name: "MINUS",
    symbol: "-"
  },
  {
    name: "EQUAL",
    symbol: "="
  },
  {
    name: "GREATER",
    symbol: ">"
  },
  {
    name: "LESSER",
    symbol: "<"
  },
  {
    name: "GREATER_EQUAL",
    symbol: ">="
  },
  {
    name: "LESSER_EQUAL",
    symbol: "<="
  },
]

let formula = [
  {
    name: "Without Formula",
    value: "without_formula"
  },
  {
    name: "ABC Formula 1",
    value: "ABC"
  },
  {
    name: "XYZ Formula 2",
    value: "XYZ"
  },
  {
    name: "PQR Formula 3",
    value: "PQR"
  },
  {
    name: "LMO Formula 4",
    value: "LMO"
  },
]

let initialValues = {
  mrr_id: 0,
  name_en: '',
  name_ar: '',
  normal_min: '',
  normal_symbol: '',
  normal_max: '',
  unit: '',
  type: '',
  formula: '',
  critical_low_symbol: '',
  critical_low_value: '',
  critical_high_symbol: '',
  critical_high_value: '',
  impossible_low_symbol: '',
  impossible_low_value: '',
  impossible_high_symbol: '',
  impossible_high_value: '',
  comment: '',
  input_json: []
}

/* const colors = {
  green: "bg-green-500",
  blue: "bg-blue-500",
  lightOrange: "bg-orange-200",
  orange: "bg-orange-500",
  darkOrange: "bg-orange-700",
  lightRed: "bg-red-200",
  red: "bg-red-500",
  darkRed: "bg-red-700"
}; */

const colors = [
  "bg-green-500",
  "bg-blue-500",
  "bg-orange-200",
  "bg-orange-500",
  "bg-orange-700",
  "bg-red-200",
  "bg-red-500",
  "bg-red-700"
]


function className(...location) {
  return location.filter(Boolean).join(' ')
}

let formStateTypeID = 0;

const AddReferenceRange = () => {
  const getCurretnDateTimeDefault = new Date().getTime();
  const pages = [{ title: "Reference Information", href: "/reference-range" }];
  const navigate = useNavigate();
  const { mrr_id: mrr_id } = useParams();
  const [dynamicInputValidate, setDynamicInputValidate] = useState(false)
  const [refTypeList, setRefTypeList] = useState([])
  const [refTypeInputsList, setRefTypeInputsList] = useState([])
  const [refTypeInputsFinalList, setRefTypeInputsFinalList] = useState([])
  let [formState, setFormState] = useState(initialValues);
  let [formStateDB, setFormStateDB] = useState({});
  let [formStateDBTypeID, setFormStateDBTypeID] = useState({});
  //let [formStateTypeID, setFormStateTypeID] = useState();
  let [formTypeSave, setFormTypeSave] = useState(true);
  let [updateInputsArray, setUpdateInputsArray] = useState([]);
  let [formStateDBInputJson, setFormStateDBInputJson] = useState([]);
  const [maxValueField, setMaxValueField] = useState("");
  const [rangeBoxes, setRangeBoxes] = useState([])
  const [positiveNegativeResult, setPositiveNegativeResult] = useState('');
  const editorRef = useRef(null);

  let [allPhasesArr, setAllPhasesArr] = useState([{id: 1, name: `Option 1`}]);
  const [colorInputList, setColorInputList] = useState([
    {
      color_name: "",
      color_is_normal: "",
      color_error: true,
      color_value: "",
      id: getCurretnDateTimeDefault,
    },
  ]);

  let loading = false;

  useEffect(() => {
    document.title = "Labib | Add-Reference";
    console.log("mrr_id ",mrr_id);
    if(mrr_id != undefined || mrr_id != null || mrr_id != ''){
      getSingleReferenceRange(mrr_id);
    }
    getRefType();
  }, []);

  const handleAddClick = () => {
    const getCurretnDateTime = new Date().getTime();
    setColorInputList([
      ...colorInputList,
      {
        color_name: "",
        color_is_normal: "",
        color_error: true,
        color_value: "",
        id: getCurretnDateTime,
      },
    ]);
  };

  useEffect(() => {
    console.log("data?.rti_title_ar", refTypeInputsList)
    // refTypeInputsList.forEach((v, index) => {
    //   if(refTypeInputsList.length === 5 && v?.rti_title_en === 'Very High') {
    //     setMaxValueField(index);        
    //   }
    // })
    //get last object of the ref type input list
    const lengOfRefTypeInputList = refTypeInputsList.length;
    //set max value field
    setMaxValueField(lengOfRefTypeInputList-1);
  }, [refTypeInputsList])

  const handleRemoveClick = (index) => {
    let list = [...colorInputList];
    const finalList = list.filter((ele, i) => i !== index);
    setColorInputList([...finalList]);
  };

  const handleColorName = (index, id, e) => {
    let list = [...colorInputList];
    const val = e.target.value;
    console.log("handleColorName", {index, id, val})
    list[index].color_name = e.target.value ? e.target.value : "";
    list[index].color_value = e.target.value ? e.target.value : "";
    list[index].color_error = e.target.value ? false : true;
    setColorInputList([...list]);
  }

  const handleColorNormal = (index, id, e) => {
    let list = [...colorInputList];
    const val = e.target.value;
    console.log("handleColorNormal", {index, id, val})
    list[index].color_is_normal = e.target.value ? e.target.value : "";
    setColorInputList([...list]);
  }

  const getRefType = async () => {
    MasterServices.getAllRefType()
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (data?.data?.length > 0) {
          setRefTypeList(data.data);
          console.log(data.data);
        } else {
          toast.error("Ref. Types Not Found")
        }
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
    });
  }

  const getSingleReferenceRange = async (mrr_id) => {
    if(parseInt(mrr_id) > 0){
      MasterServices.getSingleReferenceRange(mrr_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("getSingleReferenceRange ", data.data);

          if(data.data.mrr_id){
            let SingleReferenceRange = data.data;
            const newInitialValues = {
              mrr_id: SingleReferenceRange.mrr_id,
              name_en: SingleReferenceRange.mrr_name_en,
              name_ar: SingleReferenceRange.mrr_name_ar,
              normal_min: SingleReferenceRange.mrr_default_min,
              normal_symbol: SingleReferenceRange.mrr_symbol,
              normal_max: SingleReferenceRange.mrr_default_max,
              unit: SingleReferenceRange.mrr_unit,
              type: SingleReferenceRange.mrr_type,
              formula: SingleReferenceRange.mrr_formula,
              critical_low_symbol: SingleReferenceRange.mmr_cls,
              critical_low_value: SingleReferenceRange.mmr_clv,
              critical_high_symbol: SingleReferenceRange.mmr_chs,
              critical_high_value: SingleReferenceRange.mmr_chv,
              impossible_low_symbol: SingleReferenceRange.mmr_ils,
              impossible_low_value: SingleReferenceRange.mmr_ilv,
              impossible_high_symbol: SingleReferenceRange.mmr_ihs,
              impossible_high_value: SingleReferenceRange.mmr_ihv,
              comment: SingleReferenceRange.mrr_comments,
              input_json: SingleReferenceRange.mmr_inputs_value
            }
            formStateTypeID = parseInt(SingleReferenceRange.mrr_type);
            if(formStateTypeID === 17){
              setColorInputList(SingleReferenceRange.mmr_inputs_value);
              setFormStateDB(newInitialValues);
              setFormState(newInitialValues);
              setFormStateDBTypeID(SingleReferenceRange.mrr_type);
              setFormTypeSave(false);
            } else if(formStateTypeID == 18) {
              setAllPhasesArr(SingleReferenceRange.mmr_inputs_value);
              setFormStateDB(newInitialValues);
              setFormState(newInitialValues);
              setFormStateDBTypeID(SingleReferenceRange.mrr_type);
              setFormTypeSave(false);
            } else if(formStateTypeID == 5) {
              setPositiveNegativeResult(SingleReferenceRange?.mmr_inputs_value && SingleReferenceRange?.mmr_inputs_value[0].value)
              setFormStateDB(newInitialValues);
              setFormState(newInitialValues);
              setFormStateDBTypeID(SingleReferenceRange.mrr_type);
              setFormTypeSave(false);
            }else{
              setFormStateDBInputJson(SingleReferenceRange.mmr_inputs_value);
              updateColorRangeBoxes(1, SingleReferenceRange.mmr_inputs_value);
              setFormStateDBTypeID(SingleReferenceRange.mrr_type);
              
              setFormStateDB(newInitialValues);
              setFormState(newInitialValues);
              setFormTypeSave(false);
              if(SingleReferenceRange.mrr_type){
                getRefTypeInputsForUpdate(SingleReferenceRange.mrr_type, newInitialValues);
              }
            }

          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in getSingleReferenceRange";
        toast.error(msg)
      });
    }
  }


  // UPDATE Function
  const getRefTypeInputsForUpdate = async (type_id, dbValues) => {
    if(parseInt(type_id) > 0){

      if(updateInputsArray.length == 0){

        MasterServices.getAllRefTypeInputs(type_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {

              /* setUpdateInputsArray(data.data);
              console.log("getRefTypeInputsForUpdate", data.data);

              if(formStateDBInputJson.length > 0){
                console.log("formStateDBInputJson", formStateDBInputJson);
                data.data.map((dd, ii) => {
                  dd.rti_input_json = formStateDBInputJson[ii]
                  console.log("PPPPPPPP ", dd, formStateDBInputJson[ii])
                })
                
              }
              initialValues.input_json = formStateDBInputJson;
              setRefTypeInputsList(data.data);
              initialValues.input_json = formStateDBInputJson; */

              setUpdateInputsArray(data.data);
              console.log("getRefTypeInputsForUpdate", data.data);

              if(formStateDBInputJson.length > 0){
                console.log("formStateDBInputJson", formStateDBInputJson);
                data.data.map((dd, ii) => {
                  dd.rti_input_json = formStateDBInputJson[ii]
                  console.log("PPPPPPPP 1111", dd, formStateDBInputJson[ii])
                });
              }

              initialValues.input_json = formStateDBInputJson;
              //setFormStateDBInputJson(formStateDBInputJson);
              setRefTypeInputsList(data.data);
              updateColorRangeBoxes(2, data.data);
              initialValues.input_json = formStateDBInputJson;
              

            } else {
              toast.error("Ref. Types Inputs Not Found")
            }
          }
        })
        .catch((error) => {
          const msg = "Error in getRefTypeInputs";
          toast.error(msg)
        });

      }else{

        if(formStateDBInputJson.length > 0){
          console.log("formStateDBInputJson", formStateDBInputJson);
          updateInputsArray.map((dd, ii) => {
            dd.rti_input_json = formStateDBInputJson[ii]
            console.log("PPPPPPPP 2222", dd, formStateDBInputJson[ii])
          });
          initialValues.input_json = formStateDBInputJson;
          setRefTypeInputsList(updateInputsArray);
          updateColorRangeBoxes(0, updateInputsArray);
          initialValues.input_json = formStateDBInputJson;
        }

      }

    }
  }


  const updateColorRangeBoxes = (type, data) => {

    let updateMainArray = [...refTypeInputsList];

    console.log("updateColorRangeBoxes 1 => ", data);

    let jsonInputs = [];
    if(type == 0){
      data.map((jd, di) => {
        jsonInputs.push(jd.rti_input_json);
      })
    }else{
      jsonInputs = data;
    }

    console.log("updateColorRangeBoxes 2 => ", {type, jsonInputs, data, formStateDBInputJson, initialValues, updateMainArray});

    let newBoxexArr = [];
    if(jsonInputs.length > 0){
      jsonInputs.map((inputs, index) => {
        const addInputs = {
          id: index,
          name: inputs[1].value,
          min: inputs[2].value,
          max: inputs[4].value,
          symbol: inputs[3].value
        }
        newBoxexArr.push(addInputs);
      })
      setRangeBoxes(newBoxexArr);
      console.log("updateColorRangeBoxes 3 => ", newBoxexArr);
    }else{
      console.log("updateColorRangeBoxes 4 => ", {newBoxexArr, type, jsonInputs, data, formStateDBInputJson, initialValues, updateMainArray});
    }

  }

  // ADD Function
  const getRefTypeInputs = async (type_id) => {
    if(parseInt(type_id) > 0){
      MasterServices.getAllRefTypeInputs(type_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            
            setRefTypeInputsList(data.data);
            console.log("size of inputs", data?.data?.length);
            

            let jsonInputs = [];
            data.data.map((jd, di) => {
              jsonInputs.push(jd.rti_input_json);
            })

            setRefTypeInputsFinalList(jsonInputs);
            initialValues.input_json = jsonInputs;
            setFormState(initialValues);

            let newBoxexArr = [];
            if(jsonInputs.length > 0){
              jsonInputs.map((inputs, index) => {
                const addInputs = {
                  id: index,
                  name: data?.data[index].rti_title_en,
                  min: 0,
                  max: 0,
                  symbol: ""
                }
                newBoxexArr.push(addInputs);
              })
              setRangeBoxes(newBoxexArr);
            }
            const maindata = data?.data;
            console.log("NEW_BOXES", {maindata, jsonInputs, rangeBoxes, newBoxexArr});

          } else {
            setRefTypeInputsList([]);
            setRangeBoxes([]);
            toast.error("Ref. Types Inputs Not Found")
          }
        } else {
          setRefTypeInputsList([]);
          setRangeBoxes([]);
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in getRefTypeInputs";
        toast.error(msg)
      });
    }else{
      setRefTypeInputsList([]);
      setRangeBoxes([]);
    }
  }

  let chooseRefType = (type_id) => {
    const dbId = formStateDBTypeID;
    console.log("AJAY=>", {type_id, dbId, formStateDB});
    if(mrr_id){
      if(parseInt(type_id) == parseInt(formStateDBTypeID) && mrr_id == parseInt(formStateDB.mrr_id)){
        console.log("UPDATE");
        getRefTypeInputsForUpdate(type_id, formStateDB);

        initialValues = {
          mrr_id: mrr_id,
          name_en: formStateDB.name_en,
          name_ar: formStateDB.name_ar,
          normal_min: formStateDB.normal_min,
          normal_symbol: formStateDB.normal_symbol,
          normal_max: formStateDB.normal_max,
          unit: formStateDB.unit,
          type: type_id,
          formula: formStateDB.formula,
          critical_low_symbol: formStateDB.critical_low_symbol,
          critical_low_value: formStateDB.critical_low_value,
          critical_high_symbol: formStateDB.critical_high_symbol,
          critical_high_value: formStateDB.critical_high_value,
          impossible_low_symbol: formStateDB.impossible_low_symbol,
          impossible_low_value: formStateDB.impossible_low_value,
          impossible_high_symbol: formStateDB.impossible_high_symbol,
          impossible_high_value: formStateDB.impossible_high_value,
          comment: formStateDB.comment,
          input_json: formStateDBInputJson
        } 
      }else{
        console.log("Update ADD");
        getRefTypeInputs(type_id);
        initialValues = {
          mrr_id: mrr_id,
          name_en: formStateDB.name_en,
          name_ar: formStateDB.name_ar,
          normal_min: formStateDB.normal_min,
          normal_symbol: formStateDB.normal_symbol,
          normal_max: formStateDB.normal_max,
          unit: formStateDB.unit,
          type: type_id,
          formula: formStateDB.formula,
          critical_low_symbol: formStateDB.critical_low_symbol,
          critical_low_value: formStateDB.critical_low_value,
          critical_high_symbol: formStateDB.critical_high_symbol,
          critical_high_value: formStateDB.critical_high_value,
          impossible_low_symbol: formStateDB.impossible_low_symbol,
          impossible_low_value: formStateDB.impossible_low_value,
          impossible_high_symbol: formStateDB.impossible_high_symbol,
          impossible_high_value: formStateDB.impossible_high_value,
          comment: formStateDB.comment
        }
        if(mrr_id){
          initialValues.input_json = formStateDBInputJson;
        }
      }
    }else{
      console.log("ADD");
      getRefTypeInputs(type_id);
    }

    formStateTypeID = parseInt(type_id);

    /* if(!formTypeSave){
      if(parseInt(type_id) == parseInt(formStateDB.type)){
        console.log("UPDATE");
        getRefTypeInputsForUpdate(type_id, formStateDB);
      }else{
        console.log("ADD");
        getRefTypeInputs(type_id);
      }
    }else{
      getRefTypeInputs(type_id);
    } */
  }

  const InputBox = (lable, placeholder, type, name, id, mainIndex) => (
    <div className="">
      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">{lable}</label>
      <div className="sm:col-span-2 sm:mt-0">
        <input
          placeholder={placeholder}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
          type={type}
          name={name}
          id={id}
        />
      </div>
    </div>
  );

  const changeJsonVal = (arrIndex, inputIndex, value) => {
    //console.log("elinitialValuesement=>",initialValues);
    let updateMainArray = [...refTypeInputsList];
    let getFormStateDBInputJson = formStateDBInputJson;

    console.log("rangeBoxes=>", {updateMainArray, getFormStateDBInputJson, rangeBoxes, arrIndex, inputIndex, value});

    if(((arrIndex == 0) && (inputIndex == 2))){
      console.log("I'm working fine", 1);

      const updatedData = [...rangeBoxes];
      if(inputIndex == 2){
        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
      }else if(inputIndex == 4){
        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
      }
      setRangeBoxes(updatedData);

      updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
      updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;

      if(!formTypeSave){
        getFormStateDBInputJson[arrIndex][inputIndex].value = value;
        getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
      }

    }else if(((refTypeInputsList.length-1) == arrIndex) && (inputIndex == 4)){
      console.log("I'm working fine", 2);

      const updatedData = [...rangeBoxes];
      if(inputIndex == 2){
        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
      }else if(inputIndex == 4){
        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
      }
      setRangeBoxes(updatedData);

      updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
      updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;

      if(!formTypeSave){
        getFormStateDBInputJson[arrIndex][inputIndex].value = value;
        getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
      }

    }else{
      
      console.log("I'm working fine  333", updateMainArray);
      const updatedData = [...rangeBoxes];

      if(inputIndex == 1){
        updatedData[arrIndex].name = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;

        if(!formTypeSave){
          getFormStateDBInputJson[arrIndex][inputIndex].value = value;
          getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
        }
        console.log("updatedData", updatedData)
      }else if(inputIndex == 2){
        const val = eval(value+'-'+0.01);
        const arrayIndex = eval(arrIndex+'-'+1);
        updateMainArray[arrayIndex].rti_input_json[4].value = parseFloat(val).toFixed(2).toString();
        updateMainArray[arrayIndex].rti_input_json[4].error = val ? false :  true;

        if(!formTypeSave){
          getFormStateDBInputJson[arrayIndex][4].value = parseFloat(val).toFixed(2).toString().toString();
          getFormStateDBInputJson[arrayIndex][4].error = val ? false :  true;
        }

        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
        updatedData[arrayIndex].max = parseFloat(val).toFixed(2);
      }else if(inputIndex == 4){
        
        const val = eval(value+'+'+0.01);
        const arrayIndex = eval(arrIndex+'+'+1);
        updateMainArray[arrayIndex].rti_input_json[2].value = parseFloat(val).toFixed(2).toString();
        updateMainArray[arrayIndex].rti_input_json[2].error = val ? false :  true;

        if(!formTypeSave){
          getFormStateDBInputJson[arrayIndex][2].value = parseFloat(val).toFixed(2).toString();
          getFormStateDBInputJson[arrayIndex][2].error = val ? false :  true;
        }

        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
        updatedData[arrayIndex].min = parseFloat(val).toFixed(2);

        console.log("four =>", {arrIndex, inputIndex, val, arrayIndex, updatedData, updateMainArray});
      }else if(inputIndex == 3){
        updatedData[arrIndex].symbol = value;
        updateMainArray[arrIndex].rti_input_json[3].value = value;
        updateMainArray[arrIndex].rti_input_json[3].error = value ? false :  true;

        if(!formTypeSave){
          getFormStateDBInputJson[arrIndex][3].value = value;
          getFormStateDBInputJson[arrIndex][3].error = value ? false :  true;
        }
      }else{
        updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;

        if(!formTypeSave){
          getFormStateDBInputJson[arrIndex][inputIndex].value = value;
          getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
        }
      }

      if(!formTypeSave){
        getFormStateDBInputJson[arrIndex][inputIndex].value = value;
        getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
      }
      
      setRangeBoxes(updatedData);
      setRefTypeInputsList(updateMainArray);
      if(!formTypeSave){
        setFormStateDBInputJson(getFormStateDBInputJson);
      }
      
      console.log("rangeBoxes setted =>", {updateMainArray, getFormStateDBInputJson, rangeBoxes, arrIndex, inputIndex, value});
    }

    initialValues.input_json.map((element,index)=>{
      if(index === arrIndex){
        if(element.length>0){
          element.map((element2,index2)=>{
            if(index2 === inputIndex){
              console.log("element2", element2)
              element2.value = value;
              if(value == undefined || value == null || value == ''){
                element2.error = true;
              }else{
                element2.error = false;
              }
            }
            
          });
        }
        setFormState(initialValues);
      }
    });

  }

  let InputBoxGroup = (lable, placeholder, type, name, id, arrIndex, inputIndex) => (
    <div className="">
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
          {lable}
        </span>
        <input
          step={type == 'number' ? 0.01 : ""}
          onChange={ (e) => {changeJsonVal(arrIndex, inputIndex, e.target.value)}}
          type={type}
          name={name}
          id={id}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
          placeholder={placeholder}
          key={arrIndex+inputIndex}
          value={formTypeSave == false ? ((parseInt(formStateTypeID) == parseInt(formStateDBTypeID)) ? formStateDBInputJson[arrIndex][inputIndex].value : '') : (refTypeInputsList[arrIndex].rti_input_json[inputIndex].value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null )}
          //value={(refTypeInputsList[arrIndex].rti_input_json[inputIndex].value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null )}
        />
      </div>
        {
          formState.input_json[arrIndex][inputIndex].value == null || 
          formState.input_json[arrIndex][inputIndex].value == undefined || 
          formState.input_json[arrIndex][inputIndex].value == '' || 
          formState.input_json[arrIndex][inputIndex].error == true ||
          formState.input_json[arrIndex][inputIndex].error == undefined
          ? ((arrIndex === maxValueField && lable == 'MAX') || (arrIndex === maxValueField &&  lable == 'DropDown') ? <p></p> : 
            <p className="text-red-600 text-sm" key={arrIndex+inputIndex}>Required *</p>
          ) : null
        }
    </div>
  );

  let TinyEditor = (arrIndex, inputIndex) => (
    <div className="col-span-12">
      <p className="text-gray-500">smart report comment</p>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={e => {changeJsonVal(arrIndex, inputIndex, e)}}
        value={formTypeSave == false ? ((parseInt(formStateTypeID) == parseInt(formStateDBTypeID)) ? (Array.isArray(formStateDBInputJson[arrIndex]) && formStateDBInputJson[arrIndex]?.length > 0 && formStateDBInputJson[arrIndex][inputIndex]?.value) : '') : (refTypeInputsList[arrIndex]?.rti_input_json[inputIndex]?.value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null )}
        init={{
          height: 150,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </div>
  )

  let DropDown = (lable, name, id, arrIndex, inputIndex) => (
    <div className="">
      {/* <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">{lable}</label> */}
      <div className="mt-1 sm:col-span-2 sm:mt-1">
        <select
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
          name={name}
          id={id}
          onChange={ (e) => {changeJsonVal(arrIndex, inputIndex, e.target.value)}}
          key={arrIndex+inputIndex}
          value={formTypeSave == false ? ((parseInt(formStateTypeID) == parseInt(formStateDBTypeID)) ? formStateDBInputJson[arrIndex][inputIndex].value : '') : null}
          //value={(refTypeInputsList[arrIndex].rti_input_json[inputIndex].value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null )}
        >
          <option value="">Select Symbol</option>
          {symbols.map((d, i) => (
            <option key={i} value={d.symbol}>
              {d.symbol}
            </option>
          ))}
        </select>
      </div>
        {
          formState.input_json[arrIndex][inputIndex].value == null || 
          formState.input_json[arrIndex][inputIndex].value == undefined || 
          formState.input_json[arrIndex][inputIndex].value == '' || 
          formState.input_json[arrIndex][inputIndex].error == true ||
          formState.input_json[arrIndex][inputIndex].error == undefined
          ? ((arrIndex === maxValueField &&  lable == 'DropDown') ? <p></p> : 
            <p className="text-red-600 text-sm" key={arrIndex+inputIndex}>Required *</p>
          ) : null
        }
    </div>
  );  

  let validationSchema = () => {
    const schema = {
      name_ar: Yup.string()
        .min(2)
        .max(225)
        .required("Please Enter the Reference Name(AR)"),
      name_en: Yup.string()
        .min(2)
        .max(525)
        .required("Please Enter the Reference Name(EN)"),
      normal_min: Yup.string()
      .when(['type'], (type, schema) => {
        if(type != 17 && type != 18) {
          return schema.required("Please Enter Normal MIN")
        }
      }),
      normal_symbol: Yup.string()
      .when(['type'], (type, schema) => {
        console.log(type)
        if(type != 17 && type != 18) {
          return schema.required("Please Enter Normal Symbol")
        }
      }),
      normal_max: Yup.string()
      .when(['type'], (type, schema) => {
        if(type != 17 && type != 18) {
          return schema.required("Please Enter Normal Max")
        }
      }),
      unit: Yup.string()
      .when(['type'], (type, schema) => {
        if(type != 17 && type != 18) {
          return schema.required("Please Enter Unit")
        }
      }),
      type: Yup.string().required("Please Select Type")
        
    };
    return Yup.object().shape(schema);
  };

  let saveUpdateRef = (data, dynamic_values) => {
    
    setDynamicInputValidate(false);
    let errors = [];

    console.log("data", data);
    console.log("dynamic_values", dynamic_values);
    if(formStateTypeID == 17){
      const filteredData = colorInputList.filter(obj => obj.color_error && !obj.color_is_normal);

      if(filteredData && filteredData.length > 0 ){
        toast.error("Check all required feilds *");
        return;
      }

    }else if(formStateTypeID == 5) {

    }else{

      dynamic_values?.map((array, index) => {
        if(index === maxValueField) {
          console.log("condition true: ", index, maxValueField)
          array?.map((d, i) => {
            if(d.input_type == 'tinytext') {
              d.error = false
            }
            if(d.lable == 'MAX') {
              d.error = false
            }
            if(d.lable == 'DropDown') {
              d.error = false
            }
            // if(d.label == 'DropDown') {
            //   d.error = false
            // }
            errors.push(d.error);
          })
        } else {
          array?.map((d, i) => {
            if(d.input_type == 'tinytext') {
              d.error = false
            }
            errors.push(d.error);
          })
        }
      });

      console.log(errors)

      
  
      if (errors.includes(undefined) || errors.includes(true)) {
        toast.error("Check all required feilds *");
        return;
      }

    }

    if (mrr_id != 0) {
      data.mrr_id = mrr_id;
    }
    if(formStateTypeID == 5) {
      data.input_json = [
        {
            "error": false,
            "lable": "POS-NEG-RESULT",
            "value": positiveNegativeResult,
        },
    ];
    } else if(formStateTypeID == 17) { 
      data.input_json = colorInputList;
    } else if(formStateTypeID == 18) {
      data.input_json = allPhasesArr;
    }  else {
      data.input_json = dynamic_values;
    }
    console.log("formStateTypeID:", formStateTypeID)
    console.log("data:", data);

    MasterServices.saveReferenceRange(data)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (mrr_id > 0) {
            toast.success("Updated Successfully");
          } else {
            toast.success("Saved Successfully");
          }
          navigate("/reference-ranges");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    navigate("/reference-ranges");

  }


  return (
    <div >
      {mrr_id ? null : (
        <Breadcrumb pages={pages} />
        )
      }
      
      {mrr_id ? null : (
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back
      </button>
      )}

      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {mrr_id ? "Update Reference Info" : "Add Reference Info"}
            </h1>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (

          <Formik
            enableReinitialize={true}
            enableReinitializing
            initialValues={formState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // handle form submission
              console.log("values", values);
              console.log("formState", formState);
              saveUpdateRef(values, formState.input_json);
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
              handleReset
            }) => (

              <Form className="space-y-6 divide-gray-200">

                <div>

                  {/* First Section */}
                  <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 px-4 py-2">
                    
                    {/* Name in EN */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Name (EN)</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter Name (EN)"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="text"
                          name="name_en"
                          id="name_en"
                          value={values.name_en}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name_en && touched.name_en ? (
                          <p className="text-red-600 text-sm">{errors.name_en}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* Name in AR */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Name (AR)</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter Name (AR)"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="text"
                          name="name_ar"
                          id="name_ar"
                          value={values.name_ar}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name_ar && touched.name_ar ? (
                          <p className="text-red-600 text-sm">{errors.name_ar}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>

                  <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 px-4 py-2">

                    {/* Normal Value Min */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Default Normal - Min</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="MIN"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          disabled={values.type == 17  || values.type == 18}
                          name="normal_min"
                          id="normal_min"
                          value={values.normal_min}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.normal_min && touched.normal_min ? (
                          <p className="text-red-600 text-sm">{errors.normal_min}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* Normal Symbol */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Symbol</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="normal_symbol"
                          id="normal_symbol"
                          disabled={values.type == 17 || values.type == 18}
                          value={values.normal_symbol}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value={''}>Select Symbol</option>
                          {symbols.map((d, i) => (
                            <option key={i} value={d.symbol}>
                              {d.symbol}
                            </option>
                          ))}
                        </select>
                        {errors.normal_symbol && touched.normal_symbol ? (
                          <p className="text-red-600 text-sm">{errors.normal_symbol}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* Normal Value Max */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Default Normal - Max</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="MAX"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          disabled={values.type == 17  || values.type == 18}
                          name="normal_max"
                          id="normal_max"
                          value={values.normal_max}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.normal_max && touched.normal_max ? (
                          <p className="text-red-600 text-sm">{errors.normal_max}</p>
                        ) : null}
                      </div>
                    </div>

                  </div>

                  <div className="grid grid-cols-3 gap-3 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0 px-4 py-2">
                    
                    {/* Unit */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Unit</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter Unit"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="text"
                          disabled={values.type == 17  || values.type == 18}
                          name="unit"
                          id="unit"
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.unit && touched.unit ? (
                          <p className="text-red-600 text-sm">{errors.unit}</p>
                        ) : null}
                      </div>
                    </div>
                  {/* </div> */}
                  
                  {/* <div className="grid grid-cols-2 gap-2 lg:grid-cols-2 lg:gap-2 md:grid-cols-2 md:gap-2 sm:grid-cols-12 sm:gap-0 px-4 py-2"> */}
                    
                    {/* Type */}
                    <div >
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Type</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="type"
                          id="type"
                          onChange={(e) => {values.type = e.target.value; chooseRefType(e.target.value)}}
                          value={values.type}
                          onBlur={handleBlur}
                        >
                          <option>Select Ref. Type</option>
                          {refTypeList.map((data, i) => (
                            <option key={data.rt_id} value={data.rt_id}>
                              {data.rt_name_en}
                            </option>
                          ))}
                        </select>
                        {errors.type && touched.type ? (
                          <p className="text-red-600 text-sm">{errors.type}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* Formula */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Formula</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="formula"
                          id="formula"
                          value={values.formula}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Formula</option>
                          {formula.map((fm, i) => (
                            <option key={i} value={fm.value}>
                              {fm.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                  </div>
                  
                  {/* CRITICAL */}
                  <label className="block text-md font-large text-gray-900 sm:mt-px sm:pt-2 px-4">Critical</label>
                  <div className="grid grid-cols-4 gap-4 lg:grid-cols-4 lg:gap-4 md:grid-cols-4 md:gap-4 sm:grid-cols-12 sm:gap-0 px-4 py-2">

                    {/* Low Symbol */}
                    <div>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Low Symbol</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="critical_low_symbol"
                          id="critical_low_symbol"
                          value={values.critical_low_symbol}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Symbol</option>
                          {symbols.map((d, i) => (
                            <option key={i} value={d.symbol}>
                              {d.symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Low Optional */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Low (Optional)</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter Low Optional"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          name="critical_low_value"
                          id="critical_low_value"
                          value={values.critical_low_value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {/* High Symbol */}
                    <div>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select High Symbol</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="critical_high_symbol"
                          id="critical_high_symbol"
                          value={values.critical_high_symbol}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Symbol</option>
                          {symbols.map((d, i) => (
                            <option key={i} value={d.symbol}>
                              {d.symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* High Optional */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">High (Optional)</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter High Optional"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          name="critical_high_value"
                          id="critical_high_value"
                          value={values.critical_high_value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                  </div>

                  {/* IMPOSSIBLE */}
                  <label className="block text-md font-large text-gray-900 sm:mt-px sm:pt-2 px-4">Impossible</label>
                  <div className="grid grid-cols-4 gap-4 lg:grid-cols-4 lg:gap-4 md:grid-cols-4 md:gap-4 sm:grid-cols-12 sm:gap-0 px-4 py-2">

                    {/* Low Symbol */}
                    <div>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Low Symbol</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="impossible_low_symbol"
                          id="impossible_low_symbol"
                          value={values.impossible_low_symbol}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Symbol</option>
                          {symbols.map((d, i) => (
                            <option key={i} value={d.symbol}>
                              {d.symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Low Optional */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Low (Optional)</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter Low Optional"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          name="impossible_low_value"
                          id="impossible_low_value"
                          value={values.impossible_low_value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {/* High Symbol */}
                    <div>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select High Symbol</label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          name="impossible_high_symbol"
                          id="impossible_high_symbol"
                          value={values.impossible_high_symbol}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Symbol</option>
                          {symbols.map((d, i) => (
                            <option key={i} value={d.symbol}>
                              {d.symbol}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* High Optional */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">High (Optional)</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <input
                          placeholder="Enter High Optional"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                          type="number"
                          name="impossible_high_value"
                          id="impossible_high_value"
                          value={values.impossible_high_value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                  </div>

                  <div className="grid grid-cols-2 gap-2 lg:grid-cols-2 lg:gap-2 md:grid-cols-2 md:gap-2 sm:grid-cols-12 sm:gap-0 px-4 py-2">

                    {/* Reference Comment (overwrite medical report) */}
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Reference Comment (overwrite medical report)</label>
                      <div className="sm:col-span-2 sm:mt-0">
                        <textarea
                          placeholder="Optional"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                          type="text"
                          name="comment"
                          id="comment"
                          value={values.comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                  </div>

                  <div className="grid grid-cols-1 gap-1 lg:grid-cols-2 lg:gap-2 md:grid-cols-2 md:gap-2 sm:grid-cols-12 sm:gap-0 px-4 py-2">

                    {/* <span>
                      {JSON.stringify(formStateDBInputJson, null, 2)}
                    </span> */}
                    
                    <div className="box-container">
                    {
                      rangeBoxes?.map((data, ind) => {
                        return (
                          <div className="box">
                            <p>{data.name}</p>
                            <div className={`${colors[ind]} box-text px-2 py-2`}>{data.min + ' - ' + `${data.max ? data.max : ''}` + `${data.symbol? ' ' + data.symbol + ' ':''}`}</div>
                          </div>
                        )
                      })
                    }
                    </div>

                  </div>

                  {/* Here is dynamic Form from inputs */}
                  
                  {
                    refTypeInputsList?.length > 0 ? (<div>
                  
                      <div className="dynamicInputsSection mt-2">
                        {
                          refTypeInputsList?.map((data, index) => {
                            
                            return (
                              <div key={index}>
                                <label className="block text-md font-large text-gray-900 sm:mt-px sm:pt-2 px-4">{data?.rti_title_ar}</label>
                                <div className="grid grid-cols-5 gap-5 lg:grid-cols-5 lg:gap-5 md:grid-cols-5 md:gap-5 sm:grid-cols-12 sm:gap-0 px-4 py-2">
                                  {<>
                                  {
                                    data?.rti_input_json?.map((inputs, inpIndex) => {

                                      if(inputs?.input_type == "text"){
                                        return ( <div> {InputBoxGroup( inputs?.lable, 'Enter '+inputs?.lable, inputs?.input_type, 'text_'+inputs?.lable+index+inpIndex, 'text_'+inputs?.lable+inputs?.index+inpIndex, index, inpIndex)} </div> )
                                      }else if(inputs?.input_type == "number"){
                                        return ( <div> {InputBoxGroup( inputs?.lable, 'Enter '+inputs?.lable, inputs?.input_type, 'number_'+inputs?.lable+index+inpIndex, 'number_'+inputs?.lable+inputs?.index+inpIndex, index, inpIndex)} </div> )
                                      }else if(inputs?.input_type == "select"){
                                        return ( <div> {DropDown(inputs?.lable, 'dd_'+inputs?.lable+index+inpIndex, 'dd_'+inputs?.lable+index+inpIndex, index, inpIndex)} </div> )
                                      }else if(inputs?.input_type == 'tinytext'){ 
                                        return ( <div className="col-span-full"> {TinyEditor(index, inpIndex)} </div> )
                                      }
                                    })
                                    }
                                    </>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>) : null
                  }

                  {/********* If Color Range Type ********/}

                  {
                    formStateTypeID == 17 ? (
                        <div className="space-y-6 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5 sm:pt-5">
                            <div className="space-y-6 sm:space-y-5">
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                <label
                                  htmlFor="color"
                                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                >
                                  Color Ranges
                                </label>
                              </div>

                              {colorInputList.map((x, index) => (
                                <div
                                  key={x.id}
                                  className="mt-1 w-full flex sm:col-span-2 sm:mt-0"
                                  id={x.id}
                                >
                                  <div className="block w-full mr-4 max-w-lg focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                                    
                                    <input
                                      placeholder="Enter Color Name"
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      type="text"
                                      name="color_name"
                                      id="color_name"
                                      value={x.color_value ? x.color_value : ""}
                                      onChange={(e) => {handleColorName(index, x.id, e)}}
                                    />

                                    {
                                      colorInputList[index].color_value == null || 
                                      colorInputList[index].color_value == undefined || 
                                      colorInputList[index].color_value == '' || 
                                      colorInputList[index].color_error == true ||
                                      colorInputList[index].color_error == undefined
                                      ? (
                                        <p className="text-red-600 text-sm" key={index}>Required *</p>
                                      ) : null
                                    }

                                  </div>
                                  <div className="block w-full mr-4 max-w-lg focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                                    <select
                                      id="is_normal"
                                      name="is_normal"
                                      autoComplete="off"
                                      onChange={(e) => {handleColorNormal(index, x.id, e)}}
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    >
                                      <option value="" disabled>Is Normal?</option>
                                      <option value="yes" selected={x.color_is_normal == 'yes' ? true : false}>Yes</option>
                                      <option value="no" selected={x.color_is_normal == 'no' ? true : false}>No</option>
                                    </select>

                                    {
                                      colorInputList[index].color_is_normal == null || 
                                      colorInputList[index].color_is_normal == undefined || 
                                      colorInputList[index].color_is_normal == ''
                                      ? (
                                        <p className="text-red-600 text-sm" key={index}>Required *</p>
                                      ) : null
                                    }

                                  </div>

                                  <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                                    onClick={() => handleRemoveClick(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                                onClick={handleAddClick}
                              >
                                Add more
                              </button>
                            </div>
                          </div>
                        </div>
                    ) : null
                  }
                  {
                    formStateTypeID == 5 ? (
                        <div className="space-y-6 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5 sm:pt-5">
                            <select
                              id="posi"
                              name="is_normal"
                              autoComplete="off"
                              value={positiveNegativeResult}
                              onChange={(e) => {setPositiveNegativeResult(e.target.value)}}
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                            >
                              <option value="" disabled>Select Result</option>
                              <option value="POSITIVE" >POSITIVE</option>
                              <option value="NEGATIVE" >NEGATIVE</option>
                            </select>
                          </div>
                        </div>
                    ) : null
                  }
                  {
                    formStateTypeID == 18 ? (
                      <ReferenceRangeAddMultiple allPhasesArr={allPhasesArr} setAllPhasesArr={setAllPhasesArr}/>
                    ) : null
                  }
                </div>

                <hr />
                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>

            )}
          </Formik>

        )}
      </div>
    </div >
  );
};

export default AddReferenceRange;
