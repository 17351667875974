import { React, useState, useRef, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import Api from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
// import { Dashboard } from '@mui/icons-material';

function locationSpacificeRole(...location) {
    return location.filter(Boolean).join(' ')
}

const initialCategories = {
    Dashboard : [],
    Test: [],
    Reservation : [],
    Package : [],
    Home: [],
    User: [],
}

const EditPermissions = ({ roleList, setRolesList }) => {
    const pages = [{ title: "Add Role", href: "/add-role" }];
    const roleRef = useRef();
    const prevLocation = useLocation();
    const [location, setlocation] = useState(false)
    // const [roleName, setroleName] = useState(null)
    const [rolePermissionRes, setRolePermissionRes] = useState(false)
    const [prevPermissions, setPrevPermissions] = useState([])
    const [allPermissions, setAllPermissions] = useState(false)

    const [permissionsByCategory, setPermissionsByCategory] = useState(initialCategories)

    const navigate = useNavigate();
    const { role_id: role_id } = useParams();

    // console.log("newArrList", newArrList);
    const handleSubmit = async (event) => {
        event.preventDefault();

        let payload = {
            role_id: role_id,
            role_name_en: rolePermissionRes.role_name_en,
            role_name_ar: rolePermissionRes.role_name_ar,
            fk_permission_ids: []
        }
        payload.fk_permission_ids = prevPermissions.map((e) => {
            return e = { permission_id: e }
        })

        // console.log({ payload }, "payload")
        let res = await Api.saveRole(payload)

        if(res.status){
            // console.log("Success", res.message)
            toast.success("Role and Permissions saved successfully")
        }
    };

    useEffect(() => {
        getPermissionsWithRole(role_id)
        getAllPermissions()
    }, [])

    useEffect(() => {
        if (allPermissions) {
            sortPermissionsByCategory()
        }
    }, [allPermissions])

    // useEffect(() => {
    //     console.log(prevPermissions, permissionsByCategory, "permissionsByCategory")
    // }, [permissionsByCategory, prevPermissions])

    const getPermissionsWithRole = async (role_id) => {
        try {
            const res = await Api.getPermissionsByRoleID(role_id)
            // console.log(res)
            if (res.status) {
                // console.log(res.data.data, "Response")
                setRolePermissionRes(res.data.data)

                let prevIds = []
                res.data.data.permissions.map((p) => {
                    prevIds.push(p.permission_id)
                })
                setPrevPermissions(prevIds)
            }
        } catch (e) {
            console.log(e, "error in getPermissionsWithRole")
        }
    }

    const getAllPermissions = async () => {
        try {
            const res = await Api.getAllPermissions()
            // console.log(res)
            if (res.status) {
                // console.log(res.data.data, "allPermissions")
                setAllPermissions(res.data.data)
            }
        } catch (e) {
            console.log(e, "error in getAllPermissions")
        }
    }

    const sortPermissionsByCategory = () => {

        allPermissions.forEach((el)=>{
            if(initialCategories[el.permission_category] == undefined) {
                initialCategories[el.permission_category] = []
            }
        })

        let sortObj = {
            ...initialCategories
        }

        allPermissions.map((p) => {
            for(let key in sortObj){
                if(key === p.permission_category){
                    if(p.permission_name_en.split(" ").includes("Admin")){
                        sortObj[key] =[p, ...sortObj[key]]
                    }else{
                        sortObj[key] =[...sortObj[key], p]
                    }
                }
            }
        })

        // console.log(sortObj, "sortObj")

        setPermissionsByCategory({
           ...sortObj
        })
    }

    return (
        <div>
            <Breadcrumb pages={pages} />
            <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
                <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
            </button>
            <div className="sm:flex-auto mb-5">
                <h1 className="text-xl font-semibold text-gray-900">Edit Permission</h1>
                <h4 className='mt-4'>Role : {rolePermissionRes ? rolePermissionRes.role_name_en : ""}</h4>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                {permissionsByCategory ?
                    Object.keys(permissionsByCategory).map((cat) => {
                        return (
                            permissionsByCategory[cat].length > 0 ?
                                <div className='border border-gray-300 rounded-md px-4 py-4 mb-5 mr-4 roar'>
                                    <div className='items-center gap-5' >
                                        <div className='items-center'>
                                            <label htmlFor="role" className="block text-lg font-medium text-gray-700 mr-5">
                                                {cat}
                                            </label>
                                        </div>
                                        <div className='w-full items-center'>
                                            {/* Toggles Map */}
                                            {permissionsByCategory[cat].map((per) => {
                                                return (
                                                    <div className='flex justify-between my-4'>
                                                        <label htmlFor="role" className={`${per.permission_name_en.split(" ").includes("Admin") ? "text-cyan-500" : "text-gray-700"} block text-sm font-medium  mr-5`}>
                                                            {per.permission_name_en}
                                                        </label>
                                                        <Switch
                                                            checked={prevPermissions?.includes(per.permission_id)}
                                                            onChange={(e) => {
                                                                // console.log(e);
                                                                setPrevPermissions((p) => {
                                                                    if (e) {
                                                                        return [...p, per.permission_id]
                                                                    } else {
                                                                        let newp = [...p];
                                                                        newp.splice(newp.indexOf(per.permission_id), 1);
                                                                        return [...newp]
                                                                    }
                                                                })
                                                            }}
                                                            className={locationSpacificeRole(
                                                                prevPermissions?.includes(per.permission_id) ? 'bg-cyan-600' : 'bg-gray-200',
                                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span
                                                                aria-hidden="true"
                                                                className={locationSpacificeRole(
                                                                    prevPermissions?.includes(per.permission_id) ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                    </div>
                                </div>
                                : null
                        )
                    })
                    : null}
            </div>

            <div className='block w-full'>
                <Link
                    onClick={handleSubmit}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                >
                    Save Role and Permissions
                </Link>
            </div>


        </div>
    )
}

export default EditPermissions