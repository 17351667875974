import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddReferenceRange from "./addReferenceRange";
import SubReferenceList from "./SubReferenceRangeList";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";

const tabs = [
  { name: 'Reference Range Information', href: '#info', current: true },
  { name: 'Reference Rules', href: '#details', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function RefListTab() {

  const { mrr_id: mrr_id } = useParams();
  const [tabDetails, setOpenTab] = useState([]);
  const navigate = useNavigate();

  const ChangeTabs = (tab, index) => {
    tabs.forEach((ele, ind) => {
      ele.current = false;
    });
    console.log("Previous tab=>", tabs)
    tabs[index].current = true;

    setOpenTab(tabs);
    console.log("tab=>", tabDetails)
  }

  return (
    <div>

        <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span>   Back
        </button>

      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={() => ChangeTabs(tab, index)}
                className={classNames(
                  tab.current
                    ? 'border-cyan-500 text-cyan-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>

            ))}

            

          </nav>

          <div className="mt-5 mb-3 ">

            {tabs.map((tab, index) => (
                
                    <div className=" border-gray-200">
                        {(tab?.href === "#info" && tab?.current) ?
                        <AddReferenceRange key={index} mrr_id={mrr_id} /> : null
                        }
                        {(tab?.href === "#details" && tab?.current) ?
                        <SubReferenceList key={index} mrr_id={mrr_id} /> : null
                        }
                    </div>
                
            ))}

          </div>

        </div>
      </div>
    </div>
  );
}
export default RefListTab;