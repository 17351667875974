/* eslint-disable */
import { toast } from "react-toast";
import AuthServices from "../../ApiServices/AuthServices";
import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "./constants";

import {clearInactivityTimer, setupInactivityTimer} from "../../helper/commonHelper.js"
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";



export const getHeaders = () => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  return {
    //Authorization: `Bearer ${user.token}`,
    Authorization: `Bearer `
  };
};

// ** Handle User Login
export const handleLogin = (body) => async (dispatch) => {
  try {
    console.log("LOGINLOOOP");
    dispatch({
      type: LOGIN_BEGIN,
    });
    
    const data  = await AuthServices.Login(body);
    console.log("CALL THE LOGING AND TIMER FUN")
    setupInactivityTimer(dispatch, handleLogout);
    console.log(data);
    // localStorage.setItem("user", JSON.stringify(data.data.user));
    // localStorage.setItem("token", data.data.token);
    // console.log("handleLogin action", data.data.token);
    // console.log("user action", localStorage.getItem("user"));

    
    
    // if(!data.status){
    //   toast.error(data.message);
    // }else{
    //   dispatch({
    //     type: LOGIN_SUCCESS,
    //     data: data.data,
    //   });
    // }

  } catch (error) {
    const msg = "Failed to login catch error" //error.response.data.message;
    toast.error(msg);
    dispatch({
      type: LOGIN_ERROR,
      error: msg,
    });
  }
};

// ** Handle User Logout
export const handleLogout = (navigate) => async (dispatch) => {
  console.log("REMOVE ITEM");
  try {
      dispatch({
          type: LOGOUT_BEGIN,
      });
      clearInactivityTimer();
      console.log("handleLogout dispatch");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.clear();
      dispatch({
          type: LOGOUT_SUCCESS,
      });
      if (navigate) {
        console.log("NAVIGATING");
          navigate("/login");
      }
  } catch (error) {
      const msg = error.response?.data?.message || "Logout failed";
      toast.error(msg);
      dispatch({
          type: LOGOUT_ERROR,
          error: msg,
      });
  }
};


/* export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().get(`/api/auth`, { headers });
    console.log(data.data);

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_USERS_ERROR,
      error: msg,
    });
  }
};

export const addUser = (body) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().post(`/api/auth/register`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("User added successfully!");
    dispatch({
      type: ADD_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: ADD_USER_ERROR,
      error: msg,
    });
  }
};
 */
export const getUserWithId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().get(`/api/auth/${id}`, { headers });
    console.log(data.data);
    dispatch({
      type: GET_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_USER_ERROR,
      error: msg,
    });
  }
};

export const updateUser = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_BEGIN,
    });
    const headers = getHeaders();
    const { data } = await Api().put(`/api/auth/${id}`, body, {
      headers,
    });
    console.log(data.data);
    toast.success("User updated successfully!");
    dispatch({
      type: UPDATE_USER_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    const msg = error.response.data.message;
    toast.error(msg);
    dispatch({
      type: UPDATE_USER_ERROR,
      error: msg,
    });
  }
};
