/* eslint-disable */
import axios from 'axios'
import { toast } from 'react-toast'
import MasterServices from '../../ApiServices/MasterServices'
import {
  GET_ALL_PACKAGE_BEGIN,
  GET_ALL_PACKAGE_SUCCESS,
  GET_ALL_PACKAGE_ERROR,
  ADD_PACKAGE_BEGIN,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_ERROR,
  GET_PACKAGE_BEGIN,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_ERROR,
  UPDATE_PACKAGE_BEGIN,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_ERROR,
  DELETE_PACKAGE_BEGIN,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_ERROR
} from './constants'


export const getAllPackages = (payload) => async (dispatch) => {
  dispatch({
    type: GET_ALL_PACKAGE_BEGIN
  })

  MasterServices.getPackageList(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {

        if (data?.data?.length > 0) {
          let updatedPackages = data?.data

          updatedPackages?.forEach((element) => {
            element.label = element.package_name_en
            element.value = element.package_id
          });

          dispatch({
            type: GET_ALL_PACKAGE_SUCCESS,
            data: updatedPackages?.length > 0 ? updatedPackages : [],
          })
        } else {
          toast.error("Package Not Found")
        }
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
    });
}

export const getAllReservationPackages = (payload) => async (dispatch) => {
  dispatch({
    type: GET_ALL_PACKAGE_BEGIN
  })

  MasterServices.getReservatoinPackageList(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200) {

        if (data?.data?.length > 0) {
          let updatedPackages = data?.data

          updatedPackages?.forEach((element) => {
            element.label = element.package_name_en
            element.value = element.package_id
          });

          dispatch({
            type: GET_ALL_PACKAGE_SUCCESS,
            data: updatedPackages?.length > 0 ? updatedPackages : [],
          })
        } else {
          toast.error("Package Not Found")
        }
      } else {
        toast.error("Fatal Error Please Contact Admin")
      }
    })
    .catch((error) => {
      const msg = error.response.data.message;
      toast.error(msg)
    });
}
