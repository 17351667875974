import {
  GET_ALL_USER_BEGIN,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERROR,
  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  GET_USER_BEGIN,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "./constants";

const initState = {
  list: [],
  loading: false,
  error: null,
};

const UserReducer = (state = initState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_ALL_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        list: data,
        loading: false,
      };
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case ADD_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, data],
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case GET_USER_BEGIN:
      return {
        ...state,
        loading: true,
        userData: "",
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: data?data:[],
      };
    case GET_USER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((ele) => {
          if (ele.user_id === data.user_id) {
            return { ...data };
          } else return ele;
        }),
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((ele) => ele.user_id !== data),
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        error,
        loading: false,
      };
    default:
      return state;
  }
};

export default UserReducer;
