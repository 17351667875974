import {
	GET_ALL_ROLE_BEGIN,
	GET_ALL_ROLE_SUCCESS,
	GET_ALL_ROLE_ERROR,
	ADD_ROLE_BEGIN,
	ADD_ROLE_SUCCESS,
	ADD_ROLE_ERROR,
	GET_ROLE_BEGIN,
	GET_ROLE_ERROR,
	GET_ROLE_SUCCESS,
	UPDATE_ROLE_BEGIN,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_ERROR,
	DELETE_ROLE_BEGIN,
	DELETE_ROLE_SUCCESS,
	DELETE_ROLE_ERROR,
} from "./constants";

const initState = {
	list: [],
	loading: false,
	error: null,
};

const RoleReducer = (state = initState, action) => {
	const { type, data, error } = action;
	switch (type) {
		case GET_ALL_ROLE_BEGIN:
			return {
				...state,
				loading: true,
			};
		case GET_ALL_ROLE_SUCCESS:
			return {
				...state,
				list: data,
				loading: false,
			};
		case GET_ALL_ROLE_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		case ADD_ROLE_BEGIN:
			return {
				...state,
				loading: true,
			};
		case ADD_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				list: [...state.list, data],
			};
		case ADD_ROLE_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		case GET_ROLE_BEGIN:
			return {
				...state,
				loading: true,
				roleData: "",
			};
		case GET_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				roleData: data ? data : [],
			};
		case GET_ROLE_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		case UPDATE_ROLE_BEGIN:
			return {
				...state,
				loading: true,
			};
		case UPDATE_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				// list: state.list.map((ele) => {
				// 	if (ele.role_id === data.role_id) {
				// 		return { ...data };
				// 	} else return ele;
				// }),
			};
		case UPDATE_ROLE_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		case DELETE_ROLE_BEGIN:
			return {
				...state,
				loading: true,
			};
		case DELETE_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				list: state.list.filter((ele) => ele.role_id !== data),
			};
		case DELETE_ROLE_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		default:
			return state;
	}
};

export default RoleReducer;
