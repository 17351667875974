import {
    GET_USER_INFO_BEGIN,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_SUCCESS,
  } from "./constants";
  
  const initState = {
    userData: {},
    loading: false,
    error: null,
  };
  
  const LginUserInfoReducer = (state = initState, action) => {
    const { type, data, error } = action;
    switch (type) {
      case GET_USER_INFO_BEGIN:
        return {
          ...state,
          loading: true,
          userData: {},
        };
      case GET_USER_INFO_SUCCESS:
        return {
          ...state,
          loading: false,
          userData: data ? data: {},
        };
      case GET_USER_INFO_ERROR:
        return {
          ...state,
          error,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default LginUserInfoReducer;
  