import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Select from 'react-select'
import TestSalesChart from '../../../components/charts/testSalesChart';
import moment from "moment";

export default function TestChart({
    selectedLocationTSalesChart,
    setSelectedLocationTSalesChart,
    isTestDataLoading,
    tSalesChartFilters,
    setTSalesChartFilters,
    getTSalesData,
    l_List,
    tList,
    testSalesData,
    testSalesLabel,
    norts,
    isLoading,
    dateFilters
}) {

    useEffect(() => {
        getTSalesData()
    }, [])

    const [showCustomDates, setShowCustomDates] = useState(false)

    const handleDateFilterChange = (e) => {
        if (e.target.value == 1 && e.target[e.target.value - 1].innerHTML == "Today") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).format("YYYY-MM-DD"),
                    end_date: moment(new Date()).format("YYYY-MM-DD")
                }
            });
        } else if (e.target.value == 2 && e.target[e.target.value - 1].innerHTML == "Yesterday") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                    end_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                }
            });
        } else if (e.target.value == 3 && e.target[e.target.value - 1].innerHTML == "Last Month") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                    end_date: moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                }
            });
        } else if (e.target.value == 4 && e.target[e.target.value - 1].innerHTML == "This Month") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                    end_date: moment(new Date()).endOf('month').format('YYYY-MM-DD')
                }
            });
        } else if (e.target.value == 5 && e.target[e.target.value - 1].innerHTML == "Last Week") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).subtract(1, 'week').startOf('week').subtract(1, "day").format('YYYY-MM-DD'),
                    end_date: moment(new Date()).subtract(1, 'week').endOf('week').subtract(1, "day").format('YYYY-MM-DD'),
                }
            });
        }
        else if (e.target.value == 6 && e.target[e.target.value - 1].innerHTML == "This Week") {
            setShowCustomDates(false);
            setTSalesChartFilters((prev) => {
                return {
                    ...prev,
                    start_date: moment(new Date()).startOf('week').subtract(1, "day").format('YYYY-MM-DD'),
                    end_date: moment(new Date()).endOf('week').subtract(1, "day").format('YYYY-MM-DD'),
                }
            });
        }
        else if (e.target.value == 7 && e.target[e.target.value - 1].innerHTML == "Custom") {
            setShowCustomDates(true);
        }
    }

    const handleLocationChange = (e) => {
        setSelectedLocationTSalesChart(e)
    }

    const handleTestChange = (e) => {
        setTSalesChartFilters((prev) => {
            return { ...prev, test_ids: e }
        })
    }

    return (
        !isLoading ? <div className={`overflow-hidden bg-white shadow sm:rounded-lg mt-5 ${isTestDataLoading ? "text-gray-300" : "text-gray-500"}`}>
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Tests Sold</h3>
            </div>


            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <div className='px-4 sm:px-6'>
                    <div className="flex gap-3">
                        {/* Location Select */}
                        <div className="basis-1/3">
                            <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Locations</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <Select
                                    // defaultValue={l_List[0]}
                                    // isMulti
                                    name="fk_location_id"
                                    id="fk_location_id"
                                    value={selectedLocationTSalesChart}
                                    onChange={(e) => handleLocationChange(e)}
                                    options={l_List}
                                    // className="basic-multi-select"
                                    classNamePrefix="Select Test"
                                />
                            </div>
                        </div>


                        {/* Tests Multiselect */}
                        <div className="basis-1/3">
                            <label htmlFor="fk_test_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Tests</label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 cursor-pointer">
                                <Select
                                    isMulti
                                    name="fk_test_ids"
                                    id="fk_test_ids"
                                    value={tSalesChartFilters.test_ids}
                                    onChange={(e) => handleTestChange(e)}
                                    options={tList}
                                    className="basic-multi-select"
                                    classNamePrefix="Select Tests"
                                />
                            </div>
                        </div>

                        <div className="basis-1/3">
                            <label htmlFor="date_filter" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date Range</label>
                            <select
                                className="h-9 py-1 rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 w-60"
                                name='date_filter'
                                id='date_filter'
                                // value={}
                                defaultValue={1}
                                onChange={(e) => {
                                    //   setFilter(e.target.value || undefined);
                                    handleDateFilterChange(e)
                                }}
                            >
                                {dateFilters.map((option, i) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                    <div className="mt-5 flex items-center gap-3">
                        {/* start date */}
                        <div className="basis-1/3">
                            <input
                                disabled={isTestDataLoading || !showCustomDates}
                                className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${showCustomDates ? "border-green-500 text-gray-500" : "border-gray-300 text-gray-300"}`}
                                type="date"
                                name="start_date"
                                id="start_date"
                                value={tSalesChartFilters.start_date}
                                onChange={(e) => {
                                    setTSalesChartFilters((prev) => {
                                        if (prev.end_date < e.target.value) {
                                            return {
                                                ...prev,
                                                end_date: e.target.value,
                                                start_date: prev.end_date
                                            }
                                        }
                                        return {
                                            ...prev,
                                            start_date: e.target.value,
                                        }
                                    })
                                }
                                }
                            />
                        </div>

                        {/* end date */}
                        <div className="basis-1/3">
                            <input
                                disabled={isTestDataLoading || !showCustomDates}
                                className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm ${showCustomDates ? "border-green-500 text-gray-500" : "border-gray-300 text-gray-300"}`}
                                type="date"
                                name="end_date"
                                id="end_date"
                                value={tSalesChartFilters.end_date}
                                onChange={(e) => {
                                    setTSalesChartFilters((prev) => {
                                        if (prev.start_date > e.target.value) {
                                            return {
                                                ...prev,
                                                end_date: prev.start_date,
                                                start_date: e.target.value
                                            }
                                        }
                                        return {
                                            ...prev,
                                            end_date: e.target.value,
                                        }
                                    })
                                }
                                }
                            />
                        </div>

                        <button
                            disabled={isTestDataLoading}
                            onClick={() => {
                                getTSalesData()
                            }}
                            className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isTestDataLoading ? "opacity-40" : "opacity-100"}`}
                        >
                            Get Data
                        </button>
                        {/* <span className="text-base font-medium text-black-900 ml-2">{"(" +state.user.list.length+ ")"}</span> */}
                        {/* </div> */}
                    </div>
                </div>

                {
                    testSalesData && !isTestDataLoading ? (
                        <div className="flex flex-row text-center p-8">
                            <div className="basis-1/2">
                                <div className="w-30 mx-auto">
                                    <TestSalesChart numberOfReservationsData={norts} salesLabel={testSalesLabel} />
                                </div>
                            </div>
                        </div>
                    ) :
                        <div className="h-80">
                            <FallingLinesLoader />
                        </div>
                }

            </div>
        </div> : <FallingLinesLoader />

    )
}
