import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { test_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MasterServices from '../../../ApiServices/MasterServices';
import { read, utils, writeFile } from 'xlsx';
import moment from 'moment';
import { capitalise } from '../../../utilities/utilities';
import { convertToAnotherTimezone, removeTagsFromString } from '../../../helper/commonHelper';
import { FaSpinner } from "react-icons/fa";

function TestList() {
  const [isMicrobiology, setIsMicrobiology] = useState(false);
  const pages = [{ title: "Test List", href: "/test", module_id: 6 }];
  const [loading, setLoading] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    test_id: null,
    test_is_active: false,
  });
  const [confirmationModalDelete, setConfirmationModalDelete] = useState({
    status: false,
    test_id: null,
    test_name_en: '',
  });

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    let flag = false;
    if(tabIndex==1){
      flag = false;
    }else{
      flag = true;
    }
    setIsMicrobiology(flag);

    setActiveTab(tabIndex);
  };

  const [testList, setTestList] = useState(false);
  const [testColorList, setTestColorList] = useState([]);
  const [testCategories, setTestCategories] = useState([]);
  const [testSampleType, setTestSampleType] = useState([]);
  const [testParamsList, setTestParamsList] = useState([]);
  const [methodologyList, setMethodologyList] = useState([]);
  const [importLoader, setImportLoader] = useState(false);

  useEffect(() => {
    document.title = "Labib | Tests";
    setTimeout(() => {
      getTestList();
    }, 500)
    getTestColorList();
    getAllCategories();
    getAllSampleTypes();
    getAllTestParamsList();
    getMethodologies();
  }, []);

  const getAllTestParamsList = () => {
    let payload = {
      search: 1,
    }
    MasterServices.getTestBasedParamsExcel(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            console.log("Test paramtere List=>", data.data)
            setTestParamsList(data.data);
          } else {
            setTestParamsList([]);
          }
        } else {
          setTestParamsList([]);
        }
      })
      .catch((error) => {
        const msg = "getAllQCLotList catch Error";
        setTestParamsList([])
        toast.error(msg);
      });
  }
  const handleExport = (excel_type) => {
    console.log("testParamsList", testParamsList);
    let colors = [{label: 'Yellow', value: 1}, {label: 'Red', value: 2}];
    if (testParamsList?.length > 0) {
      let newSheetdata = [];
      const uniqueArray = removeDuplicatesByTestName(testParamsList)
      uniqueArray.map((obj, index) => {

        let SN = parseInt(index) + parseInt(1);
        let TEST_NAME_EN = (obj?.t_test_relation?.test_name_en);
        let TEST_NAME_AR = (obj?.t_test_relation?.test_name_ar);
        let TEST_CODE = (obj?.t_test_relation?.test_code);
        let TEST_COLOR = (obj?.t_test_relation?.color_relation?.mclr_name);
        let HOSPITAL_PRICE = (obj?.t_test_relation?.hos_price);
        let TEST_SMART_REPORT_DESCRIPTION_EN = (obj?.t_test_relation?.test_smart_report_description_en);
        let TEST_SMART_REPORT_DESCRIPTION_AR = (obj?.t_test_relation?.test_smart_report_description_ar);
        let TEST_SHORT_DESCRIPTION_EN = (obj?.t_test_relation?.test_short_description_en);
        let TEST_SHORT_DESCRIPTION_AR = (obj?.t_test_relation?.test_short_description_ar);
        let TEST_TIPS_DESCRIPTION_EN = (obj?.t_test_relation?.test_tips_description_en);
        let TEST_TIPS_DESCRIPTION_AR = (obj?.t_test_relation?.test_tips_description_ar);
        let TEST_TAT = (obj?.t_test_relation?.test_tat);
        let TEST_STAT = (obj?.t_test_relation?.test_stat);
        let IS_MICROBIOLOGY = (obj?.t_test_relation?.is_microbiology);
        // let Reference_Range = (obj?.reference_ranges_relation?.mrr_name_en);
        // let Smart_Report_Description_EN = removeTagsFromString(obj?.ttp_smart_report_description_en);test_smart_report_description_en
        // let Smart_Report_Description_AR = removeTagsFromString(obj?.ttp_smart_report_description_ar);
        // let Short_Description_EN = removeTagsFromString(obj?.ttp_short_description_en);
        // let Short_Description_AR = removeTagsFromString(obj?.ttp_short_description_ar);
        // let Date = moment(convertToAnotherTimezone(obj.StartDateF)).format('DD-MM-YYYY') + " / " + moment(convertToAnotherTimezone(obj.EndDateF)).format('DD-MM-YYYY');
        // let MonthName = moment(obj.StartDateF).toLocaleString(undefined, { month: 'long' });
        const newData = {
          SN: SN,
          TEST_NAME_EN,
          TEST_NAME_AR,
          TEST_CODE: TEST_CODE,
          TEST_COLOR: TEST_COLOR,
          HOSPITAL_PRICE,
          TEST_SMART_REPORT_DESCRIPTION_EN,
          TEST_SMART_REPORT_DESCRIPTION_AR,
          TEST_SHORT_DESCRIPTION_EN,
          TEST_SHORT_DESCRIPTION_AR,
          TEST_TIPS_DESCRIPTION_EN,
          TEST_TIPS_DESCRIPTION_AR,
          TEST_TAT,
          TEST_STAT,
          IS_MICROBIOLOGY
          // Reference_Range: Reference_Range,
          // Smart_Report_Description_EN: Smart_Report_Description_EN,
          // Smart_Report_Description_AR: Smart_Report_Description_AR,
          // Short_Description_EN: Short_Description_EN,
          // Short_Description_AR: Short_Description_AR,
        }
        newSheetdata.push(newData);
      })
      let fileName = 'ALL_Test_Parameter_LIST_Report_' + '.' + excel_type;
      const headings = [['SN', 
      'TEST_NAME_EN', 'TEST_NAME_AR', 
      'TEST_CODE', 'TEST_COLOR', 'HOSPITAL_PRICE', 
      'TEST_SMART_REPORT_DESCRIPTION_EN',
      'TEST_SMART_REPORT_DESCRIPTION_AR',
      'TEST_SHORT_DESCRIPTION_EN',
      'TEST_SHORT_DESCRIPTION_AR',
      'TEST_TIPS_DESCRIPTION_EN',
      'TEST_TIPS_DESCRIPTION_AR',
      'TEST_TAT',
      'TEST_STAT',
      'IS_MICROBIOLOGY'
    ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }
  const getTestColorList = () => {
    MasterServices.getTestColorList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setTestColorList(data?.data);
            console.log("setTestColorList", data?.data);
          }
        }
      })
      .catch((error) => {
        const msg = "Error in Test Color";
        toast.error(msg);
      });
  }

  const getAllCategories = () => {
    MasterServices.getCategoryList('')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setTestCategories(data?.data);
          console.log("setTestCategories", data?.data);
        }
      })
      .catch((error) => {
        const msg = "Error in getAllCategorys";
        toast.error(msg);
      });
  }

  const getAllSampleTypes = () => {
    MasterServices.getSampleTypeList("")
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setTestSampleType(data?.data);
            console.log("setTestSampleType", data?.data);
          }
        }
      })
      .catch((error) => {
        const msg = "Error in getAllSampleTypes";
        toast.error(msg);
      });
  }

  const getTestList = async () => {
    MasterServices.getTestList('All')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Test Found");
            setTestList(data.data);
          } else {
            toast.error("Test Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        console.log(msg)
        setLoading(false);
      });

  }

  function removeDuplicatesByTestName(arr) {
    const seen = new Set();
    const uniqueArray = arr.filter(item => {
      const duplicate = seen.has(item?.t_test_relation?.test_name_en);
      seen.add(item?.t_test_relation?.test_name_en);
      return !duplicate;
    });
    return uniqueArray;
  }

  const onDeleteOpen = (test_id, test_is_active) => {
    setConfirmationModal({ test_id, test_is_active, status: true });
  };

  const onTestDeleteOpen = (test_id, test_name_en) => {
    setConfirmationModalDelete({ test_id, status: true, test_name_en });
  };

  const onDeleteTest = async (test_id, test_is_active) => {
    let Newtest_is_active = false;
    Newtest_is_active = test_is_active == true ? false : true;
    let text = "Activated";
    if (Newtest_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setTestStatus({
      test_id,
      test_is_active: Newtest_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Test " + text + " Successfully!")
          setConfirmationModal({ test_id: null, status: false });
          getTestList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error;
        console.log(msg);
        // toast.error(msg)
      });
  };

  const onDelete = async (test_id) => {

    MasterServices.deleteTest({
      test_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success(data?.message || "Test Deleted Successfully")
          setConfirmationModalDelete({ test_id: null, status: false });
          getTestList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error;
        console.log(msg);
        // toast.error(msg)
      });
  };

  const filterCategory = (category) => {
    const result = testCategories.filter(obj => obj.category_name_en === category);
    let resp = null;
    if (result.length > 0) {
      resp = result[0].category_id
    } else {
      resp = undefined;
    }
    return resp;
  }

  const filterSample = (sample) => {
    const result = testSampleType.filter(obj => obj.sample_type_name_en === sample);
    let resp = null;
    if (result.length > 0) {
      resp = result[0].sample_type_id
    } else {
      resp = undefined;
    }
    return resp;
  }

  // Get all methodologies
  const getMethodologies = async () => {
    MasterServices.getMethodology()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setMethodologyList(data.data);
          } else {
            toast.error("Methodology List Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "Error in Methodology";
        toast.error(msg);
      });
  };

  // filter Methodologies
  const filterMethodology = (methodology) => {
    const result = methodologyList.filter(
      (obj) => obj.methodology_name === methodology
    );
    let resp = null;
    if (result.length > 0) {
      resp = result[0].methodology_id;
    }
    return resp;
  };

  const handleImport = ($event) => {
    setImportLoader(true);
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Test handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {

            rows.map((obj, index) => {

              if (obj.TEST_NAME != undefined || obj.TEST_NAME != '') {
                const newObj = {
                  test_name_en: obj?.TEST_NAME_EN,
                  test_name_ar: obj?.TEST_NAME_AR,
                  TEST_COLOR: obj?.TEST_COLOR,
                  hos_price: `${obj?.HOSPITAL_PRICE}`,
                  test_min_price: `${obj?.TEST_MIN_PRICE}`,
                  test_code: `${obj?.TEST_CODE}`,

                  test_smart_report_description_en: `${obj?.TEST_SMART_REPORT_DESCRIPTION_EN}`,
                  test_smart_report_description_ar: `${obj?.TEST_SMART_REPORT_DESCRIPTION_AR}`,
                  test_short_description_en: `${obj?.TEST_SHORT_DESCRIPTION_EN}`,
                  test_short_description_ar: `${obj?.TEST_SHORT_DESCRIPTION_AR}`,

                  test_tat: obj?.TEST_TAT,
                  test_stat: obj?.TEST_STAT,
                  // fk_hos_id: 2,

                  // fk_color_id: testColorList.filter(color => color.mclr_hex_code === obj.colour)?.mclr_id,
                  // fk_category_id: filterCategory(obj.category), // testCategories.filter(category => category.category_name_en === obj.category),
                  // fk_sample_type_id: filterSample(obj.sample_type), // testSampleType.filter(sample => sample.sample_type_name_en === obj.sample_type)?.sample_type_id,
                  // fk_methodology_id: filterMethodology(obj.methodology)
                };

                newData.push(newObj);
              }

            });

            console.log("newArray: ", newData);
            // const filteredArray = newData.filter(obj => obj.fk_sample_type_id !== undefined && obj.fk_category_id !== undefined);
            // console.log("filteredArray: ", filteredArray);

            // if (filteredArray.length > 0) {
            //   importTest(filteredArray);
            // }

            if (newData.length > 0) {
              importTest(newData);
            }
            

          } else {
            setImportLoader(false);
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importTest = (data) => {
    const payload = { excel_data: data }
    MasterServices.importTest(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setImportLoader(false);
          toast.success("Test Imported Successfully");
        } else {
          setImportLoader(false);
          toast.error(data.message)
        }
      })
      .catch((error) => {
        setImportLoader(false);
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  // Filter the test list based on the isMicrobiology flag
  const filteredTestList = testList.length>0?testList.filter((test) => test.is_microbiology === isMicrobiology):[];
  // const filteredTestList = testList.filter((test) =>
  //   isMicrobiology ? test.includes('Microbiology') : !test.includes('Microbiology')
  // );
  return (

    <div className="border rounded-lg overflow-hidden">
      <div className="flex">
        <button
          onClick={() => handleTabChange(1)}
          className={`py-2 px-4 text-sm font-medium ${activeTab === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} focus:outline-none`}
        >
          Normal Test List
        </button>
        <button
          onClick={() => handleTabChange(2)}
          className={`py-2 px-4 text-sm font-medium ${activeTab === 2 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} focus:outline-none`}
        >
          Microbilology Test List
        </button>
        {/* Add more tab buttons as needed */}
      </div>

      <div className="p-4 bg-white rounded-lg">

        <div className="px-4 sm:px-6 lg:px-8">
          <ConfirmationModal
            title={"Are you sure you want to " + (confirmationModal.test_is_active ? "Disable " : "Activate") + " this Test?"}
            // confirmationButtonText={confirmationModal.test_is_active ? "Disable" : "Activate"}
            confirmationButtonText="Yes"
            // description={"Do you really want to " + (confirmationModal.test_is_active ? "Disable" : "Activate") + " Test"}
            open={confirmationModal.status}
            onDelete={() => onDeleteTest(confirmationModal.test_id, confirmationModal.test_is_active)}
            setOpen={setConfirmationModal}
          />
          <ConfirmationModal
            title={"Are you sure you want to Delete " + `'${confirmationModalDelete.test_name_en}'` + " this Test?"}
            // confirmationButtonText={confirmationModal.test_is_active ? "Disable" : "Activate"}
            confirmationButtonText="Yes"
            // description={"Do you really want to " + (confirmationModal.test_is_active ? "Disable" : "Activate") + " Test"}
            open={confirmationModalDelete.status}
            onDelete={() => onDelete(confirmationModalDelete.test_id)}
            setOpen={setConfirmationModalDelete}
          />
          <Breadcrumb pages={pages} />
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">{activeTab === 1?'Normal Test List':'Microbilology Test List'}</h1>

          </div>
          <div className="mt-4 flex">
            {!importLoader ? 
            <input type="file"
            onChange={handleImport}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
              placeholder="Import File"
              />
            :           
            <>Importing ..<FaSpinner icon="spinner" className="spinner ml-2" /></>
            }

            <Link
              to="/add-test"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            >
              Add
            </Link>
            {testParamsList?.length > 0 ? (
              <>
                <button
                  onClick={() => handleExport('xlsx')}
                  className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 justify-items-end">
                  Generate Excel
                </button>

              </>

            ) : (<></>)}
          </div>
          <div className=" max-w-full overscroll-auto">
          {
            loading ? (
              <FallingLinesLoader />
            ) : ((filteredTestList.length > 0) ? (
              <Table
              zoom='zoom-075'
                columns={test_columns({ onDeleteOpen, onTestDeleteOpen })}
                data={filteredTestList}
              />
            ) : <TableEmpty />)
          }
          </div>
          
        </div >
      </div>

    </div>

  );
}
export default TestList;