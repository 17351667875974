import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { refRulesSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { sub_reference_range_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils } from 'xlsx';
import { Editor } from '@tinymce/tinymce-react';
import { Switch } from '@headlessui/react';
import SubReferenceRangePhasesComponent from "./SubReferenceRangePhasesComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let symbols = [
  {
    name: "ADD",
    symbol: "+"
  },
  {
    name: "MINUS",
    symbol: "-"
  },
  {
    name: "EQUAL",
    symbol: "="
  },
  {
    name: "GREATER",
    symbol: ">"
  },
  {
    name: "LESSER",
    symbol: "<"
  },
  {
    name: "GREATER_EQUAL",
    symbol: ">="
  },
  {
    name: "LESSER_EQUAL",
    symbol: "<="
  },
]

let gender = [
  {
    name: "Female",
    value: "FEMALE"
  },
  {
    name: "Male",
    value: "MALE"
  }
]

const colors = [
  "bg-green-500",
  "bg-blue-500",
  "bg-orange-200",
  "bg-orange-500",
  "bg-orange-700",
  "bg-red-200",
  "bg-red-500",
  "bg-red-700"
]

let initialValues = {
  pk_mrr_id: 0,
  fk_mrr_id: 0,
  /* age_from: "",
  age_to: "", */
  normal_min: "",
  normal_max: "",
  gender: "",
  critical_low_symbol: '',
  critical_low_value: '',
  critical_high_symbol: '',
  critical_high_value: '',
  impossible_low_symbol: '',
  impossible_low_value: '',
  impossible_high_symbol: '',
  impossible_high_value: '',
  comment: '',
  Type: "Add",
  from_day: 1,
  from_month: 0,
  from_year: 0,
  to_day: 1,
  to_month: 0,
  to_year: 0,
  input_json: []
};

const onePhaseObj = [{
  name: "Phase 1",
  id: 1,
  value: [
    [
      {
        error: false,
        lable: "AR",
        value: "Low",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "Low",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
    [
      {
        error: false,
        lable: "AR",
        value: "Normal",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "Normal",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
    [
      {
        error: false,
        lable: "AR",
        value: "High",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "High",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
  ],
}];

let newObjectTest = {
  name: "Phase 1",
  id: 1,
  value: [
    [
      {
        error: false,
        lable: "AR",
        value: "Low",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "Low",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
    [
      {
        error: false,
        lable: "AR",
        value: "Normal",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "Normal",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
    [
      {
        error: false,
        lable: "AR",
        value: "High",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "EN",
        value: "High",
        input_type: "text",
        placeholder: "",
      },
      {
        error: false,
        lable: "MIN",
        value: "",
        input_type: "number",
        placeholder: "",
      },
      {
        error: false,
        lable: "DropDown",
        value: "",
        input_type: "select",
        placeholder: "",
      },
      {
        error: false,
        lable: "MAX",
        value: "",
        input_type: "number",
        placeholder: "",
      },
    ],
  ],
}

let formStateTypeID = 0;

function SubReferenceList(data) {
  const pages = [{ title: "Reference Rules List (Sub-Ref. Ranges)", href: "/reference-ranges" }];
  const [loading, setLoading] = useState(true)
  const [modalData, setModalData] = useState(initialValues);
  const [openRulesModal, setOpenRulesModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const editorRef = useRef(null);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mrr_id: null
  });
  const [referenceRangeList, setReferenceRangeList] = useState(false)

  const navigate = useNavigate();



  const [dynamicInputValidate, setDynamicInputValidate] = useState(false)
  const [refTypeList, setRefTypeList] = useState([])
  const [refTypeInputsList, setRefTypeInputsList] = useState([])
  const [originalRefTypeInputsList, setOriginalRefTypeInputsList] = useState([])
  const [maxValueField, setMaxValueField] = useState("");
  const [refTypeInputsFinalList, setRefTypeInputsFinalList] = useState([])
  let [formState, setFormState] = useState(initialValues);
  let [formStateDB, setFormStateDB] = useState({});
  let [formStateDBTypeID, setFormStateDBTypeID] = useState({});
  let [formTypeSave, setFormTypeSave] = useState(true);
  let [updateInputsArray, setUpdateInputsArray] = useState([]);
  let [formStateDBInputJson, setFormStateDBInputJson] = useState([]);
  let [rangeBoxes, setRangeBoxes] = useState([]);

  let [isPhases, setIsPhases] = useState(false)
  let [allPhasesArr, setAllPhasesArr] = useState(onePhaseObj);

  useEffect(() => {
    getReferenceRangeList();
    getSingleReferenceRange(data.mrr_id);
    document.title = "Sub - Reference Ranges (Rules)";
  }, []);

  const daysArray = Array.from({ length: 31 }, (_, index) => index + 1);
  const monthsArray = Array.from({ length: 13 }, (_, index) => index);
  const yearsArray = Array.from({ length: 150 }, (_, index) => index);

  const getReferenceRangeList = async () => {
    MasterServices.getAllSubReferenceRange(data.mrr_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setReferenceRangeList(data.data);
          } else {
            toast.error("Reference Ranges Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg)
        setLoading(false);
      });

  }

  useEffect(() => {
    console.log("data?.rti_title_ar", refTypeInputsList)
    // refTypeInputsList.forEach((v, index) => {
    //   if(refTypeInputsList.length === 5 && v?.rti_title_en === 'Very High') {
    //     setMaxValueField(index);        
    //   }
    // })
    //get last object of the ref type input list
    const lengOfRefTypeInputList = refTypeInputsList.length;
    //set max value field
    setMaxValueField(lengOfRefTypeInputList-1);
  }, [refTypeInputsList])

  const getSingleReferenceRange = async (mrr_id) => {
    if(parseInt(mrr_id) > 0){
      MasterServices.getSingleReferenceRange(mrr_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("getSingleReferenceRange", data?.data);
          

          const updatedData = data?.data.mmr_inputs_value.map((innerArray) => {
            return innerArray.map((obj) => {
              const { value, ...rest } = obj; // Destructure 'value' key and capture the rest of the properties
              return { ...rest, error: false }; // Spread the remaining properties and set 'error' to false
            });
          });

          setFormStateDBInputJson(data?.data.mmr_inputs_value);
          setFormStateDBTypeID(data?.data.mrr_type);
          setFormTypeSave(false);
          initialValues.input_json = updatedData;
          setFormState(initialValues);
          getRefTypeInputs(data?.data?.r_type_relation?.rt_id);

        }
      })
      .catch((error) => {
        const msg = "Error in getSingleReferenceRange";
        toast.error(msg)
      });
    }
  }

  useEffect(() => {
    console.log(modalData)
    if(modalData.Type == 'Edit'){
      console.log("useEffect")
      setOpenRulesModal(true);
    }
  }, [modalData])

  const onViewClick = async (data) => {
    console.log(data)
    let modal = {
      fk_mrr_id: 0,
      fk_mrr_id: parseInt(data.mrr_id),
      /* age_from: "",
      age_to: "", */
      normal_min: data?.mrr_sub_normal_min,
      normal_max: data?.mrr_sub_normal_max,
      gender: data?.mrr_gender,
      critical_low_symbol: data?.mmr_cls,
      critical_low_value: data?.mmr_clv,
      critical_high_symbol: data?.mmr_chs,
      critical_high_value: data?.mmr_chv,
      impossible_low_symbol: data?.mmr_ils,
      impossible_low_value: data?.mmr_ilv,
      impossible_high_symbol: data?.mmr_ihs,
      impossible_high_value: data?.mmr_ihv,
      comment: data?.mrr_comments,
      Type: "Edit",
      from_day: 1,
      from_month: 0,
      from_year: 0,
      to_day: 1,
      to_month: 0,
      to_year: 0,
      mrr_is_phases_list: data?.mrr_is_phases_list,
      input_json: data?.mmr_inputs_value
    };
    setIsPhases(data?.mrr_is_phases_list)
    console.log(data?.mrr_is_phases_list);
    if(data?.mrr_is_phases_list) {
      let output = [];
      console.log(data?.mmr_inputs_value)
      for(let i=0; i<data?.mmr_inputs_value.length; i++) {
        let temarr=data?.mmr_inputs_value[i];
        console.log("tempArr", temarr)
        let keyValueArr = [];
        // Iterate over each key in the data object
          for (let key in temarr) {
            console.log(`Processing ${key}:`);
            const array = temarr[key];
            console.log(array)
            keyValueArr.push({
              name: key,
              value: array
            })
          }
        output = keyValueArr;
      }
      setAllPhasesArr(output);
      modal.input_json = output;
    }
    if(Array.isArray(data?.mmr_inputs_value) && !data?.mrr_is_phases_list){
      setRefTypeInputsList(prev => {
        console.log(prev)
        let a = data?.mmr_inputs_value.map((v, i) => {
          console.log({...originalRefTypeInputsList[i], rti_input_json: v})
          return {
            ...originalRefTypeInputsList[i],
            rti_input_json: v
          }
        })
        return a
      });
    }
    setModalData((prev) => ({
      ...prev,
      ...modal,
    }));
    console.log(modalData)
  }

  const onDeleteOpen = (mrr_id) => {
    setConfirmationModal({ status: true, mrr_id });
  };

  const onDeleteCategory = (mrr_id) => {

    MasterServices.removeRefRule({ mrr_id })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Rule Removed Successfully!");
          getReferenceRangeList();
          setConfirmationModal({ mrr_id: null, status: false });
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in removing rule";
        toast.error(msg)
      });
    setConfirmationModal({ mrr_id: null, status: false });
  };


  useEffect(() => {
    console.log("getReferenceRangeList =>", referenceRangeList)
  }, [referenceRangeList]);


  const closeModal = () => {

    let resetModal = {
      pk_mrr_id: 0,
      fk_mrr_id: parseInt(data.mrr_id),
      /* age_from: "",
      age_to: "", */
      normal_min: "",
      normal_max: "",
      gender: "",
      critical_low_symbol: '',
      critical_low_value: '',
      critical_high_symbol: '',
      critical_high_value: '',
      impossible_low_symbol: '',
      impossible_low_value: '',
      impossible_high_symbol: '',
      impossible_high_value: '',
      comment: '',
      Type: "Add",
      from_day: 1,
      from_month: 0,
      from_year: 0,
      to_day: 1,
      to_month: 0,
      to_year: 0,
    };

    setModalData((prev) => ({
      ...prev,
      ...resetModal,
    }));
    setRefTypeInputsList(originalRefTypeInputsList)
    setOpenRulesModal(false);
    setIsPhases(false)
    setAllPhasesArr(onePhaseObj)
  };

  const addRefRules = (body, action, dynamic_values) => {

    setDynamicInputValidate(false);
    let errors = [];

    // dynamic_values?.map((array, index) => {
    //   array?.map((d, i) => {
    //     errors.push(d.error);
    //   })
    // });
    dynamic_values?.map((array, index) => {
      if(index === maxValueField) {
        console.log("condition true: ", index, maxValueField)
        array?.map((d, i) => {
          if(d.input_type == 'tinytext') {
            d.error = false
          }
          if(d.lable == 'MAX') {
            d.error = false
          }
          if(d.lable == 'DropDown') {
            d.error = false
          }
          // if(d.label == 'DropDown') {
          //   d.error = false
          // }
          errors.push(d.error);
        })
      } else {
        array?.map((d, i) => {
          if(d.input_type == 'tinytext') {
            d.error = false
          }
          errors.push(d.error);
        })
      }
    });

    console.log("errors", errors);

    if (errors.includes(undefined) || errors.includes(true)) {
      toast.error("Check all required feilds *");
      return;
    }

    console.log("dynamic_values", dynamic_values);


    if (parseInt(data.mrr_id) > 0) {
      body.fk_mrr_id = parseInt(data.mrr_id)
    }
    console.log("addRefRules => ", body);
    
    body.input_json = dynamic_values ? dynamic_values : [];
    MasterServices.saveSubReferenceRange(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success(`Added Successfully`);
          getReferenceRangeList();
          setOpenRulesModal(false);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "saveSubReferenceRange Catch Error";
        toast.error(msg);
      });
    action.resetForm();
    closeModal();
  };

  // UPDATE Function
  const getRefTypeInputs = async (type_id) => {
    if(parseInt(type_id) > 0){

      if(updateInputsArray.length == 0){

        MasterServices.getAllRefTypeInputs(type_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {
              // if(Array.isArray(data.data) && data.data.length > 0) {
              //   data.data = data.data.map(v => {
              //     const rti_input_json = v.rti_input_json.map(p => {
              //       if(p.input_type != 'tinytext') {
              //         return p
              //       } else {
              //         return undefined
              //       }
              //     }).filter(vp => vp !== undefined)
              //     return {
              //       ...v,
              //       rti_input_json
              //     }
              //   })
              // } 
              console.log("data.data", data.data)
              setUpdateInputsArray(data.data);
              setRefTypeInputsList(data.data);
              setOriginalRefTypeInputsList(data.data);

              let jsonInputs = [];
              data.data.map((jd, di) => {
                jsonInputs.push(jd.rti_input_json);
              })

              initialValues.input_json = jsonInputs;
              setFormState(initialValues);

              let newBoxexArr = [];
              if(jsonInputs.length > 0){
                jsonInputs.map((inputs, index) => {
                  const addInputs = {
                    id: index,
                    name: data?.data[index].rti_title_en,
                    min: 0,
                    max: 0,
                    symbol: ""
                  }
                  newBoxexArr.push(addInputs);
                })
                setRangeBoxes(newBoxexArr);
              }
              
            }
          }else{
            setRefTypeInputsList([]);
          }
        })
        .catch((error) => {
          const msg = "Error in getRefTypeInputs";
          toast.error(msg)
        });

      }

    }
  }


  let TinyEditor = (arrIndex, inputIndex) => (
    <div className="col-span-12">
      <p className="text-gray-500">smart report comment</p>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={e => {changeJsonVal(arrIndex, inputIndex, e)}}
        value={refTypeInputsList[arrIndex]?.rti_input_json[inputIndex]?.value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null }
        init={{
          height: 150,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </div>
  )


  let InputBoxGroup = (lable, placeholder, type, name, id, arrIndex, inputIndex) => (
    <div className="">
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
          {lable}
        </span>
        <input
          step={type == 'number' ? 0.01 : ''}
          onChange={ (e) => {changeJsonVal(arrIndex, inputIndex, e.target.value)}}
          type={type}
          name={name}
          id={id}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
          placeholder={placeholder}
          key={arrIndex+inputIndex}
          value={refTypeInputsList[arrIndex].rti_input_json[inputIndex].value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null}
        />
      </div>
        {
          formState.input_json[arrIndex][inputIndex].value == null || 
          formState.input_json[arrIndex][inputIndex].value == undefined || 
          formState.input_json[arrIndex][inputIndex].value == '' || 
          formState.input_json[arrIndex][inputIndex].error == true ||
          formState.input_json[arrIndex][inputIndex].error == undefined
          ? ((arrIndex === maxValueField && lable == 'MAX') ? <p></p> : 
            <p className="text-red-600 text-sm" key={arrIndex+inputIndex}>Required *</p>
          ) : null
        }
    </div>
  );

  let DropDown = (lable, name, id, arrIndex, inputIndex) => (
    <div className="">
      {/* <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">{lable}</label> */}
      <div className="mt-1 sm:col-span-2 sm:mt-1">
        <select
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
          name={name}
          id={id}
          onChange={ (e) => {changeJsonVal(arrIndex, inputIndex, e.target.value)}}
          key={arrIndex+inputIndex}
          value={refTypeInputsList[arrIndex].rti_input_json[inputIndex].value ? refTypeInputsList[arrIndex].rti_input_json[inputIndex].value : null}
        >
          <option value="">Select Symbol</option>
          {symbols.map((d, i) => (
            <option key={i} value={d.symbol}>
              {d.symbol}
            </option>
          ))}
        </select>
      </div>
        {
          formState.input_json[arrIndex][inputIndex].value == null || 
          formState.input_json[arrIndex][inputIndex].value == undefined || 
          formState.input_json[arrIndex][inputIndex].value == '' || 
          formState.input_json[arrIndex][inputIndex].error == true ||
          formState.input_json[arrIndex][inputIndex].error == undefined
          ? ((arrIndex === maxValueField && lable == 'DropDown') ? <p></p> : 
            <p className="text-red-600 text-sm" key={arrIndex+inputIndex}>Required *</p>
          ) : null
        }
    </div>
  );

  const changeJsonVal = (arrIndex, inputIndex, value) => {
    
    let updateMainArray = [...refTypeInputsList];

    console.log("changeJsonVal=>", {updateMainArray, rangeBoxes, arrIndex, inputIndex, value});

    if(((arrIndex == 0) && (inputIndex == 2))){
      console.log("I'm working fine", 1);

      let updatedData = [...rangeBoxes];
      if(inputIndex == 2){
        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
      }else if(inputIndex == 4){
        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
      }
      setRangeBoxes(updatedData);

    }else if(((refTypeInputsList.length-1) == arrIndex) && (inputIndex == 4)){
      console.log("I'm working fine", 2);

      let updatedData = [...rangeBoxes];
      if(inputIndex == 2){
        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
      }else if(inputIndex == 4){
        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
      }
      setRangeBoxes(updatedData);

    }else{
      
      console.log("I'm working fine  333", updateMainArray);

      let updatedData = [...rangeBoxes];
      console.log("before updateMainArray", updateMainArray);
      if(inputIndex == 1){
        updatedData[arrIndex].name = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;

        // if(!formTypeSave){
        //   getFormStateDBInputJson[arrIndex][inputIndex].value = value;
        //   getFormStateDBInputJson[arrIndex][inputIndex].error = value ? false :  true;
        // }
      } else if(inputIndex == 2){
        const val = eval(value+'-'+0.01);
        const arrayIndex = eval(arrIndex+'-'+1);
        updateMainArray[arrayIndex].rti_input_json[4].value = parseFloat(val).toFixed(2).toString();
        updateMainArray[arrayIndex].rti_input_json[4].error = val ? false :  true;
        updatedData[arrIndex].min = parseFloat(value).toFixed(2);
        updatedData[arrayIndex].max = parseFloat(val).toFixed(2);
      }else if(inputIndex == 4){
        
        const val = eval(value+'+'+0.01);
        const arrayIndex = eval(arrIndex+'+'+1);
        updateMainArray[arrayIndex].rti_input_json[2].value = parseFloat(val).toFixed(2).toString();
        updateMainArray[arrayIndex].rti_input_json[2].error = val ? false :  true;
        updatedData[arrIndex].max = parseFloat(value).toFixed(2);
        updatedData[arrayIndex].min = parseFloat(val).toFixed(2);

        console.log("four =>", {arrIndex, inputIndex, val, arrayIndex, updatedData, updateMainArray});
      }else if(inputIndex == 3){
        updatedData[arrIndex].symbol = value;
        updateMainArray[arrIndex].rti_input_json[3].value = value;
        updateMainArray[arrIndex].rti_input_json[3].error = value ? false :  true;
      }else{
        updateMainArray[arrIndex].rti_input_json[inputIndex].value = value;
        updateMainArray[arrIndex].rti_input_json[inputIndex].error = value ? false :  true;
      }

      setRangeBoxes(updatedData);
      setRefTypeInputsList(updateMainArray);
      //setFormStateDBInputJson(updateMainArray);

    }

    initialValues.input_json.map((element,index)=>{
      if(index === arrIndex){
        if(element.length>0){
          element.map((element2,index2)=>{
            if(index2 === inputIndex){
              element2.value = value;
              if(value == undefined || value == null || value == ''){
                element2.error = true;
              }else{
                element2.error = false;
              }
            }
            
          });
        }
        setFormState(initialValues);
      }
    });

  }

  const importSubReferenceRangeAPICall = (data) => {
    const payload = { excel_data: data }
    console.log("importSubReferenceRangeAPICall", payload);
    
    MasterServices.importSubReferenceRange(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("Imported sub reference range data:", data)
          toast.success("Sub Reference Ranges Imported Successfully");
        } else {
          toast.error(data.message)
        }
        getReferenceRangeList();
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log("handleImport in Sub-RR: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.reference_range_name != undefined) {
                const newObj = {
                  mrr_name_en: obj.reference_range_name,

                  normal_min: obj.sub_normal_min,
                  normal_max: obj.sub_normal_max,
                  gender: obj.gender,
                  comment: obj.reference_comments,

                  from_day: obj.from_day,
                  from_month: obj.from_month,
                  from_year: obj.from_year,

                  to_day: obj.to_day,
                  to_month: obj.to_month,
                  to_year: obj.to_year,

                  critical_low_symbol: obj.critical_low_symbol,
                  critical_low_value: obj.citical_low_value,
                  critical_high_symbol: obj.critical_high_symbol,
                  critical_high_value: obj.critical_high_value,

                  impossible_low_symbol: obj.impossible_low_symbol,
                  impossible_low_value: obj.impossible_low_value,
                  impossible_high_symbol: obj.impossible_high_symbol,
                  impossible_high_value: obj.impossible_high_value,

                  // input_json: obj.input_json,
                 };
                newData.push(newObj);
              }
            });
            console.log("newArray: ", newData);
            // const filteredArray = newData.filter(obj => obj.fk_rr_id !== undefined && obj.fk_test_id !== undefined);
            // console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              // console.log("newData", newData)
              newData.mrr_is_phases_list = true;
              importSubReferenceRangeAPICall(newData);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleConvertAllPhasesArrToJSON = (phaseArrToConvert) => {
    let ans = [];
    let obj = {};

    for (let phase of phaseArrToConvert) {
      let phaseName = phase.name;
      let phaseValue = phase.value;
      obj[phaseName] = phaseValue;
    }
    ans = [obj];
    return ans;
  };

  const handleSaveRefRangeRuleWithPhases = (body, action) =>{
    // Use this to handle Saving of Ref Range Rule for Phases
    console.log("allPhasesArr", allPhasesArr)
    
    body.input_json = allPhasesArr ? handleConvertAllPhasesArrToJSON(allPhasesArr) : [];

    MasterServices.saveSubReferenceRange(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success(`Added Successfully`);
          getReferenceRangeList();
          setOpenRulesModal(false);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "saveSubReferenceRange Catch Error";
        toast.error(msg);
      });
    action.resetForm();
    closeModal();
  } 

  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <ConfirmationModal
        title={"Are you sure you want to Remove this Rule?"}
        confirmationButtonText="yes"
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCategory(confirmationModal.mrr_id)}
      />

      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Reference Ranges (Rules)</h1>
      </div>
      <div className="mt-4 flex">
        <input type="file"
          onChange={handleImport}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          placeholder="Import File"
        />
        <Link
          onClick={() => { setOpenRulesModal(true); }}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Reference Rule
        </Link>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((referenceRangeList.length > 0) ? (
          <Table
            columns={sub_reference_range_columns({ onDeleteOpen, onViewClick })}
            data={referenceRangeList}
          />
        ) : <TableEmpty />)
      }


      <Transition.Root show={openRulesModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => { setOpenRulesModal(false); closeModal() }}
        >

          <Transition.Child
            afterLeave={() => {
              closeModal()
            }}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Formik
            enableReinitialize={true}
            enableReinitializing
            onSubmit={(values, action) => {

              let addObj = {
                /* age_from: values.age_from,
                age_to: values.age_to, */
                normal_min: values.normal_min,
                normal_max: values.normal_max,
                gender: values.gender,
                critical_low_symbol: values.critical_low_symbol,
                critical_low_value: values.critical_low_value,
                critical_high_symbol: values.critical_high_symbol,
                critical_high_value: values.critical_high_value,
                impossible_low_symbol: values.impossible_low_symbol,
                impossible_low_value: values.impossible_low_value,
                impossible_high_symbol: values.impossible_high_symbol,
                impossible_high_value: values.impossible_high_value,
                comment: values.comment,
                Type: "Add",
                from_day: values.from_day,
                from_month: values.from_month,
                from_year: values.from_year,
                to_day: values.to_day,
                to_month: values.to_month,
                to_year: values.to_year,
              }

              addObj.fk_mrr_id = parseInt(data.mrr_id)

              if(isPhases) {
                handleSaveRefRangeRuleWithPhases(addObj, action);
                return true
              }
              addRefRules(addObj, action, formState.input_json);
              //closeModal();

            }}
            initialValues={modalData}
            validationSchema={refRulesSchema}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
              handleReset
            }) => (

              <div className="fixed  inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden md:max-w-7xl w-full lg:max-w-7xl rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                      <div className="w-1/1">
                        <form noValidate
                          onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                              <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                  Add Ref. Rules
                                </h3>
                              </div>

                              <div className="space-y-6 sm:space-y-5">
                                <div className="grid grid-cols-2 gap-4">

                                  {/* Age From */}
                                  <div className="">
                                    <label
                                      htmlFor="age_from"
                                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Age From
                                    </label>
                                  </div>

                                  {/* Age to */}
                                  <div className="">
                                    <label
                                      htmlFor="age_to"
                                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Age To
                                    </label>
                                  </div>
                                  <div className="flex gap-3">
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Day</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="from_day"
                                          id="from_day"
                                          value={values.from_day}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {daysArray.map((d, i) => (
                                            <option key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Month</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="from_month"
                                          id="from_month"
                                          value={values.from_month}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {monthsArray.map((d, i) => (
                                            <option  key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Year</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="from_year"
                                          id="from_year"
                                          value={values.from_year}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {yearsArray.map((d, i) => (
                                            <option  key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex gap-3">
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Day</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="to_day"
                                          id="to_day"
                                          value={values.to_day}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {daysArray.map((d, i) => (
                                            <option key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Month</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="to_month"
                                          id="to_month"
                                          value={values.to_month}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {monthsArray.map((d, i) => (
                                            <option key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="basis-1/3">
                                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Year</label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                          name="to_year"
                                          id="to_year"
                                          value={values.to_year}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {yearsArray.map((d, i) => (
                                            <option key={d} value={d}>
                                              {d}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Min Normal */}
                                  <div className="">
                                    <label
                                      htmlFor="normal_min"
                                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Min Normal
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="number"
                                        name="normal_min"
                                        id="normal_min"
                                        value={values.normal_min}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Min Normal`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                      />
                                      {
                                        errors.normal_min && touched.normal_min ? (
                                          <p className="text-red-600 text-sm">{errors.normal_min}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  {/* Max Normal */}
                                  <div className="">
                                    <label
                                      htmlFor="normal_max"
                                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Max Normal
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="number"
                                        name="normal_max"
                                        id="normal_max"
                                        value={values.normal_max}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Max Normal`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                      />
                                      {
                                        errors.normal_max && touched.normal_max ? (
                                          <p className="text-red-600 text-sm">{errors.normal_max}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  {/* Gender */}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Gender</label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="gender"
                                        id="gender"
                                        value={values.gender}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option>Select Gender</option>
                                        {gender.map((d, i) => (
                                          <option key={i} value={d.value}>
                                            {d.name}
                                          </option>
                                        ))}
                                      </select>
                                      {
                                        errors.gender && touched.gender ? (
                                          <p className="text-red-600 text-sm">{errors.gender}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  {values.gender == "FEMALE" ? (
                                    <div className="inline-flex align-middle mt-8 mr-2">
                                    <Switch
                                      checked={isPhases}
                                      onChange={(event) => {
                                        setIsPhases(event);
                
                                        // initialValues.mc_is_outsource = event;
                                        // formState.mc_is_outsource = event;
                                        // setFormState(formState);
                                        console.log("isPhases", event);
                                      }}
                                      className={classNames(
                                        isPhases ? "bg-cyan-600" : "bg-gray-200",
                                        "inline-flex relative h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          isPhases
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        )}
                                      />
                                    </Switch>
                                    <p className="text-gray-500 text-xs pt-1 pl-2">Phases</p>
                                  </div>
                                  ) : null}

                                </div>

                                {/* CRITICAL */}
                                <label className="block text-md font-large text-gray-900 sm:pt-1">Critical</label>
                                <div className="grid grid-cols-4 gap-4 lg:grid-cols-4 lg:gap-4 md:grid-cols-4 md:gap-4 sm:grid-cols-12 sm:gap-0 py-1">

                                  {/* Low Symbol */}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Low Symbol</label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="critical_low_symbol"
                                        id="critical_low_symbol"
                                        value={values.critical_low_symbol}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option>Select Symbol</option>
                                        {symbols.map((d, i) => (
                                          <option key={i} value={d.symbol}>
                                            {d.symbol}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* Low Optional */}
                                  <div className="">
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Low (Optional)</label>
                                    <div className="sm:col-span-2 sm:mt-0">
                                      <input
                                        placeholder="Enter Low Optional"
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        type="number"
                                        name="critical_low_value"
                                        id="critical_low_value"
                                        value={values.critical_low_value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>

                                  {/* High Symbol */}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select High Symbol</label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="critical_high_symbol"
                                        id="critical_high_symbol"
                                        value={values.critical_high_symbol}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option>Select Symbol</option>
                                        {symbols.map((d, i) => (
                                          <option key={i} value={d.symbol}>
                                            {d.symbol}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* High Optional */}
                                  <div className="">
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">High (Optional)</label>
                                    <div className="sm:col-span-2 sm:mt-0">
                                      <input
                                        placeholder="Enter High Optional"
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        type="number"
                                        name="critical_high_value"
                                        id="critical_high_value"
                                        value={values.critical_high_value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>

                                </div>

                                {/* IMPOSSIBLE */}
                                <label className="block text-md font-large text-gray-900 sm:pt-1">Impossible</label>
                                <div className="grid grid-cols-4 gap-4 lg:grid-cols-4 lg:gap-4 md:grid-cols-4 md:gap-4 sm:grid-cols-12 sm:gap-0 py-2">

                                  {/* Low Symbol */}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Low Symbol</label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="impossible_low_symbol"
                                        id="impossible_low_symbol"
                                        value={values.impossible_low_symbol}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option>Select Symbol</option>
                                        {symbols.map((d, i) => (
                                          <option key={i} value={d.symbol}>
                                            {d.symbol}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* Low Optional */}
                                  <div className="">
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Low (Optional)</label>
                                    <div className="sm:col-span-2 sm:mt-0">
                                      <input
                                        placeholder="Enter Low Optional"
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        type="number"
                                        name="impossible_low_value"
                                        id="impossible_low_value"
                                        value={values.impossible_low_value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>

                                  {/* High Symbol */}
                                  <div>
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select High Symbol</label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="impossible_high_symbol"
                                        id="impossible_high_symbol"
                                        value={values.impossible_high_symbol}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option>Select Symbol</option>
                                        {symbols.map((d, i) => (
                                          <option key={i} value={d.symbol}>
                                            {d.symbol}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* High Optional */}
                                  <div className="">
                                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">High (Optional)</label>
                                    <div className="sm:col-span-2 sm:mt-0">
                                      <input
                                        placeholder="Enter High Optional"
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        type="number"
                                        name="impossible_high_value"
                                        id="impossible_high_value"
                                        value={values.impossible_high_value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>

                                </div>

                                <div className="grid grid-cols-2 gap-2 lg:grid-cols-2 lg:gap-2 md:grid-cols-2 md:gap-2 sm:grid-cols-12 sm:gap-0 py-2 space-y-8 divide-y divide-gray-200 sm:space-y-5">

                                  {/* Reference Comment (overwrite medical report) */}
                                  <div className="">
                                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Reference Comment (overwrite medical report)</label>
                                    <div className="sm:col-span-2 sm:mt-0">
                                      <textarea
                                        placeholder="Optional"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                        type="text"
                                        name="comment"
                                        id="comment"
                                        value={values.comment}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>

                                </div>

                                {!isPhases ? (
                                  <div className="box-container">
                                  {
                                    rangeBoxes?.map((data, ind) => {
                                      return (
                                        <div className="box">
                                          <p>{data.name}</p>
                                          <div className={`${colors[ind]} box-text px-2 py-2`}>{data.min + ' - ' + data.max + `${data.symbol? ' ' + data.symbol + ' ':''}`}</div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                ) : null}

                                {
                                  isPhases ? 
                                  <SubReferenceRangePhasesComponent symbols={symbols} allPhasesArr={allPhasesArr} setAllPhasesArr={setAllPhasesArr} onePhaseObj={onePhaseObj} newObjectTest={newObjectTest}/>
                                  
                                  : (
                                    <div>
                                  {
                                      refTypeInputsList?.length > 0 ? (<div>
                                    
                                        <div className="dynamicInputsSection mt-2">
                                          {
                                            refTypeInputsList?.map((data, index) => {
                                              
                                              return (
                                                <div key={index}>
                                                  <label className="block text-md font-large text-gray-900 sm:mt-px sm:pt-2 px-4">{data?.rti_title_ar}</label>
                                                  <div className="grid grid-cols-5 gap-5 lg:grid-cols-5 lg:gap-5 md:grid-cols-5 md:gap-5 sm:grid-cols-12 sm:gap-0 px-4 py-2">
                                                    {
                                                      Array.isArray(data.rti_input_json) && data.rti_input_json.map((inputs, inpIndex) => {
                                                        console.log(inputs?.input_type)
                                                        if(inputs?.input_type == "text"){
                                                          return ( <div> {InputBoxGroup( inputs?.lable, 'Enter '+inputs?.lable, inputs?.input_type, 'text_'+inputs?.lable+index+inpIndex, 'text_'+inputs?.lable+index+inpIndex, index, inpIndex)} </div> )
                                                        }else if(inputs?.input_type == "number"){
                                                          return ( <div> {InputBoxGroup( inputs?.lable, 'Enter '+inputs?.lable, inputs?.input_type, 'number_'+inputs?.lable+index+inpIndex, 'number_'+inputs?.lable+inputs?.index+inpIndex, index, inpIndex)} </div> )
                                                        }else if(inputs?.input_type == "select"){
                                                          return ( <div> {DropDown( inputs?.lable, 'dd_'+inputs?.lable+index+inpIndex, 'dd_'+inputs?.lable+index+inpIndex, index, inpIndex)} </div> )
                                                        }else if(inputs?.input_type == 'tinytext'){ 
                                                          return ( <div className="col-span-full"> {TinyEditor(index, inpIndex)} </div> )
                                                        }
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                    </div>) : null
                                  }
                                </div>
                                  )
                                }
                                

                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                              <div className="flex justify-end">
                              <button
                                onClick={() => { setOpenRulesModal(false); closeModal(); }}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                Cancel
                              </button>
                                {modalData.Type != 'Edit' && !isPhases ? (
                              <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                Save
                              </button>
                              ) : (<button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              // onClick={()=>{
                              //   handleSaveRefRangeRuleWithPhases()
                              // }}
                              >
                              Save
                            </button>
                            )} 
                            </div>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>

            )}
          </Formik>
        </Dialog>
      </Transition.Root>

    </div >
  );
}
export default SubReferenceList;