import moment from "moment";
import { useEffect, useRef, useState } from "react";
import MasterServices from "../../../../ApiServices/MasterServices";
import Select from "react-select";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import { location_revenue_report_columns } from "../../../../components/tables/tableheader";
import Breadcrumb from "../../../../components/Breadcrumb";
import { getAllLocations } from "../../../../redux/location/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toast";
import { convertToAnotherTimezone } from "../../../../helper/commonHelper";

const initialLocationRevenueReportFilters = {
  start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  loc_id: 0,
};

export default function DailyLocationCashRevenue() {
  const pages = [
    { title: "Location Revenue Report", href: "/location-revenue" },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [locationRevenueReportData, setLocationRevenueReportData] = useState(
    []
  );
  console.log(locationRevenueReportData);
  const [locationRevenueReportFilters, setLocationRevenueReportFilters] =
    useState(initialLocationRevenueReportFilters);
  const printRef = useRef();
  const dispatch = useDispatch();

  const {
    location: { loading: lLoading, list: l_List },
  } = useSelector((state) => state);
  const [selectedLocation, setSelectedLocation] = useState(l_List[0]);

  useEffect(() => {
    // getAllUsers();
    
    dispatch(getAllLocations());
  }, []);

  useEffect(() => {
    if (l_List && l_List.length > 0) {
      // setSelectedLocation(l_List[0]);
      const userLocData = JSON.parse(localStorage.getItem("userLocData"));
      let currentfilter = l_List.filter(v => v.loc_id == userLocData[0].value)[0];
      setSelectedLocation(currentfilter);
      setLocationRevenueReportFilters((prev) => {
        return {
          ...prev,
          loc_id: [currentfilter.loc_id],
        };
      });
    }
  }, [l_List]);


  useEffect(() => {
    getLocationRevenueReport();
  }, [locationRevenueReportFilters])
  async function getLocationRevenueReport() {
    setIsLoading(true);
    try {
      const { data, status } =
        await MasterServices.getDailyLocationBasedRevenue(
          locationRevenueReportFilters
        );
      if (status === 200) {
        setLocationRevenueReportData(data.data.cash_collected_data);
        toast.success("Location Revenue Data fetched successfully!");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  async function handleGeneratePDF() {
    // setIsLoading(true);
    let total_amount = 0;
    let pdf_data = locationRevenueReportData.map((el) => {
      total_amount = total_amount + +el.cash_collected;
      return {
        ...el,
        date: moment(convertToAnotherTimezone(el.date)).format("DD-MM-YYYY"),
      };
    });
    let body = {
      start_date: locationRevenueReportFilters.start_date,
      end_date: locationRevenueReportFilters.end_date,
      revenue_data: pdf_data,
      loc_id: locationRevenueReportFilters.loc_id,
      total_amount,
    };
    try {
      const { data, status } =
        await MasterServices.downloadDailyLocationBasedRevenuePDF(body);
      if (status === 200) {
        if (data?.data?.pdf_url != "") {
          window.open(data.data.pdf_url, "__blank");
        }
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  }

  const handleMultiLocations = (event) => {
    setSelectedLocation(event);

    setLocationRevenueReportFilters((prev) => {
      return {
        ...prev,
        loc_id: event.loc_id,
      };
    });
  };

  //   const handleExport = (excel_type) => {
  //     if (couponReportData?.length > 0) {
  //       let newSheetdata = [];
  //       couponReportData.map((obj, index) => {
  //         const newData = {
  //           User: capitalise(obj.user_relation.user_first_name),
  //           ReservationId: obj.fk_reservation_id,
  //           CouponName: obj.coupon_relation.cp_name,
  //           CouponCode: obj.coupon_code_details.cpc_code,
  //           CouponIsPercentage: obj.coupon_relation?.cp_value_is_percentage
  //             ? "Yes"
  //             : "No",
  //           CouponValue: obj.coupon_relation.cp_discountValue,
  //           TotalDiscount: obj.total_discount,
  //         };
  //         newSheetdata.push(newData);
  //       });
  //       let fileName =
  //         "Coupon_Report_From_" +
  //         moment(couponReportFilters.start_date).format("DD-MM-YYYY") +
  //         "_To_" +
  //         moment(couponReportFilters.end_date).format("DD-MM-YYYY") +
  //         "." +
  //         excel_type;

  //       const headings = [
  //         [
  //           "User",
  //           "Reservation Id",
  //           "Coupon Name",
  //           "Coupon Code",
  //           "Coupon Is Percentage",
  //           "Coupon Value",
  //           "Total Discount",
  //         ],
  //       ];
  //       const wb = utils.book_new();
  //       const ws = utils.json_to_sheet([]);
  //       utils.sheet_add_aoa(ws, headings);
  //       utils.sheet_add_json(ws, newSheetdata, {
  //         origin: "A2",
  //         skipHeader: true,
  //       });
  //       utils.book_append_sheet(wb, ws, "Report");
  //       writeFile(wb, fileName);
  //     } else {
  //       toast.error("Data Not Found!");
  //     }
  //   };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">
            Location Revenue Report
          </h1>
        </div>

        <div className="mt-5 flex gap-3 items-center">
          {/* start date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date From
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={locationRevenueReportFilters.start_date}
              onChange={(e) => {
                setLocationRevenueReportFilters((prev) => {
                  if (prev.end_date < e.target.value) {
                    return {
                      ...prev,
                      end_date: e.target.value,
                      start_date: prev.end_date,
                    };
                  }
                  return {
                    ...prev,
                    start_date: e.target.value,
                  };
                });
              }}
            />
          </div>

          {/* end date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date To
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={locationRevenueReportFilters.end_date}
              onChange={(e) => {
                setLocationRevenueReportFilters((prev) => {
                  if (prev.start_date > e.target.value) {
                    return {
                      ...prev,
                      end_date: prev.start_date,
                      start_date: e.target.value,
                    };
                  }
                  return {
                    ...prev,
                    end_date: e.target.value,
                  };
                });
              }}
            />
          </div>

          {!lLoading && l_List ? (
            <div className="">
              <label
                htmlFor="fk_location_ids"
                className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Location
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                  // defaultValue={testList[0]}
                  //   isMulti
                  name="fk_location_ids"
                  id="fk_location_ids"
                  value={selectedLocation}
                  onChange={(e) => handleMultiLocations(e)}
                  options={l_List}
                  className="basic-multi-select"
                  classNamePrefix="Select Locations"
                />
              </div>
            </div>
          ) : null}

          <button
            disabled={isLoading}
            onClick={() => {
              getLocationRevenueReport();
            }}
            className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${
              isLoading ? "opacity-40" : "opacity-100"
            }`}
          >
            Get Data
          </button>
        </div>

        {locationRevenueReportData?.length > 0 ? (
          <div className="mt-5 flex gap-1 items-center">
            {/* <button
            onClick={() => handleExport("csv")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            CSV
          </button> */}

            {/* <button
            onClick={() => handleExport("xlsx")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            Excel
          </button> */}

            <button
              onClick={() => handleGeneratePDF()}
              className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              PDF
            </button>
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : locationRevenueReportData.length > 0 ? (
        <Table
          printRef={printRef}
          columns={location_revenue_report_columns()}
          data={locationRevenueReportData}
        />
      ) : (
        <TableEmpty />
      )}
    </>
  );
}
