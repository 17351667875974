import React, { useEffect, useRef } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Combobox, Switch } from "@headlessui/react";
import { clinicSchema } from "../../../schemas";
import { addClinic } from "../../../redux/clinic/actions";
import MasterServices from "../../../ApiServices/MasterServices";
import { singleUploadFilesWithUpdate } from "../../../helper/multiUploadHelper";
import { toast } from "react-toast";
import CreditList from "./CreditList";
import { getAllLocations } from "../../../redux/location/actions";
import { getUsersList } from "../../../redux/patientUserList/actions";
import { FaSpinner } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Clinic", href: "#clinic", current: true },
  { name: "Credit Details", href: "#credit-details", current: false },
];

const AddOutsourcedClinic = () => {
  const pages = [
    {
      title: "Add Clinic Account",
      href: "/add-outsourced-clinic",
      module_id: 26,
    },
  ];

  const {
    location: { loading: lLoading, list: l_List }
  } = useSelector((state) => state);

  const [enabledCod, setEnabledCod] = useState(false);
  const [enabledPrepaid, setEnabledPrepared] = useState(false);
  const [enabledCredit, setEnabledCredit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enabledTransfer, setEnabledTransfer] = useState(false);
  const [isOutsourced, setIsOutsourced] = useState(true);
  const { id: Iid } = useParams();

  const [tabDetails, setOpenTab] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contryList, setContryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [clinicData, setClinicData] = useState({});
  const [priceList, setPriceList] = useState(false);
  const [singlePriceListId, setSinglePriceListId] = useState(null);

  const profileImageRef = useRef();
  const [profileImage, setProfileImage] = useState();

  const [selectedLocation, setSelectedLocation] = useState("")

  async function getCountries() {
    try {
      const contriesFromApi = await MasterServices.getCountryList();
      setContryList(contriesFromApi.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function getCityListByState(payload) {
    try {
      const citiesFromApi = await MasterServices.getCityListByState(payload);
      setCityList(citiesFromApi.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  async function getStateListByCountry(payload) {
    try {
      const statesFromApi = await MasterServices.getStateListByCountry(payload);
      setStateList(statesFromApi.data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (Iid) getClinicById(Iid);
    console.log("Iid", Iid)
    getAllPriceList();
  }, [Iid]);

  const getClinicById = (id) => {
    setLoading(true);
    MasterServices.getSingleClinic(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setClinicData(data.data);
          if (data?.data?.mc_profile_pic != null) {
            setProfileImage(data.data?.mc_profile_pic);
          }
          if (data.data?.mc_payment_type === "POSTPAID") {
            setPaymentMode(data.data?.mc_payment_type);
          }

          if(data.data.fk_hsp_loc_id){
            setSelectedLocation(l_List.filter(el=> el.loc_id == data.data.fk_hsp_loc_id ))
          }

          if (data?.data?.m_price_list_relation != null) {
            setSinglePriceListId(data?.data?.m_price_list_relation);
            console.log(
              "data?.data?.m_price_list_relation",
              data?.data?.m_price_list_relation
            );
          }
          console.log(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountries();
    dispatch(getAllLocations())
  }, []);

  let initialValues = {
    mc_name: "",
    mc_phone: "",
    mc_email: "",

    mc_address: "",
    mc_postalCode: "",
    mc_fax: "",

    mc_vat: "",
    mc_oc_discount: "",
    mc_website: "",
    mc_latitude: "",
    mc_longitude: "",

    mc_payment_type: "",
    mc_credit_limit: "",
    mc_interval: "",

    fk_city_id: "",
    fk_state_id: "",
    fk_country_id: "",

    mc_cod: false,
    mc_prepaid: false,
    mc_credit: false,
    mc_transfer: false,
    mc_is_outsource: true,
  };

  const payment_type = ["PREPAID", "POSTPAID"];
  const payment_interval = ["MONTHLY", "QUARTERLY", "BI-ANNUALLY", "YEARLY"];

  const [formState, setFormState] = useState(initialValues);
  const [getPaymentMode, setPaymentMode] = useState(null);

  useEffect(() => {
    if (Iid && clinicData) {
      setFormState(clinicData);
      setEnabledCod(clinicData.mc_cod);
      setEnabledPrepared(clinicData.mc_prepaid);
      setEnabledCredit(clinicData.mc_credit);
      setEnabledTransfer(clinicData.mc_transfer);
      setIsOutsourced(clinicData.mc_is_outsource);
    }
  }, [Iid, clinicData]);

  const setSelectedPM = async (events) => {
    console.log("setSelectedPM events", events.target.value);
    let mode = events.target.value ? events.target.value : null;
    if (mode) {
      setPaymentMode(mode);
    } else {
      setPaymentMode(null);
    }
  };

  const getAllPriceList = () => {
    MasterServices.getPriceList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            //setPriceList(data.data);
            console.log("PRICE_LIST", data.data);
            if (data?.data?.length > 0) {
              let newPriceList = [];
              data.data.map((obj, index) => {
                obj.label = obj.price_name_en + " (" + obj.price_type + ")";
                obj.value = obj.price_id;
                newPriceList.push(obj);
              });
              setPriceList(newPriceList);
            } else {
              toast.error("Test Not Found");
            }
          } else {
            toast.error("PriceList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllPriceList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const singlePriceList = (event) => {
    event.label = event.price_name_en + " (" + event.price_type + ")";
    event.value = event.price_id;
    console.log(event)
    setSinglePriceListId(event);
  };

  const onChangeClick = () => {
    profileImageRef.current.click();
  };

  const handleFileChange = async (field, file) => {
    const response = await singleUploadFilesWithUpdate(
      clinicData,
      file,
      "clinic"
    );
    if (field == "profileImage") {
      setProfileImage(response.data);
    }
  };

  const ChangeTabs = (tab, index) => {
    tabs.forEach((ele, ind) => {
      ele.current = false;
    });
    console.log("Previous tab=>", tabs);
    tabs[index].current = true;

    setOpenTab(tabs);
    console.log("tab=>", tabDetails);
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: clinicSchema,
      onSubmit: async (values, action) => {
        const body = { ...values };
        if (Iid) {
          body.mc_id = Iid;
        }
        body.mc_cod = enabledCod;
        body.mc_prepaid = enabledPrepaid;
        body.mc_credit = enabledCredit;
        body.mc_transfer = enabledTransfer;
        body.mc_is_outsource = isOutsourced;
        body.fk_hsp_loc_id = selectedLocation?.loc_id
        if (singlePriceListId) {
          body.price_id = singlePriceListId.price_id;
        } 
        //  else {
        //   toast.error("Please Select PriceList");
        //   return false;
        // }
        setLoading(true);
        console.log("body=>", body);
        await dispatch(addClinic(body));
        action.resetForm();
        setEnabledCod(false);
        setEnabledCredit(false);
        setEnabledPrepared(false);
        setEnabledTransfer(false);
        setIsOutsourced(true);
        setSelectedLocation("");
        await dispatch(getUsersList());
        navigate(-1);
        setLoading(false);
      },
    });

  return (
    <div>
      <Breadcrumb pages={pages} />

      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {Iid
            ? tabs.map((tab) => <option key={tab.name}>{tab.name}</option>)
            : tabs
                .slice(0, 1)
                .map((tab) => <option key={tab.name}>{tab.name}</option>)}
        </select>
      </div>

      <div className="hidden sm:block">
        <div>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {Iid
              ? tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => ChangeTabs(tab, index)}
                    className={classNames(
                      tab.current
                        ? "border-cyan-500 text-cyan-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))
              : tabs.slice(0, 1).map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => ChangeTabs(tab, index)}
                    className={classNames(
                      tab.current
                        ? "border-cyan-500 text-cyan-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
          </nav>
          {tabs.map((tab, index) => (
            <div className="hidden sm:block">
              <div className="mt-5">
                {tab?.href === "#clinic" && tab?.current ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                    className="space-y-8 divide-y divide-gray-200"
                  >
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                        <div>
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Add Account
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            A list of all the Outsourced Clinic Account.
                          </p>
                        </div>

                        {/* CLinic Profile Pic */}
                        <div className="mt-1 sm:col-span-2 sm:mt-0 basis-1/4">
                          <div className="flex items-center flex-col">
                            <span className="h-28 w-28 overflow-hidden rounded-full bg-gray-100">
                              {!profileImage ? (
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              ) : (
                                <img src={profileImage} alt="profile-image" />
                              )}
                            </span>
                            <input
                              id="user_profile_image"
                              onChange={(e) =>
                                handleFileChange(
                                  "profileImage",
                                  e.target.files[0]
                                )
                              }
                              ref={profileImageRef}
                              type="file"
                              hidden
                            />
                            <button
                              type="button"
                              onClick={onChangeClick}
                              className="mt-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Change
                            </button>
                            <span className="mt-1 max-w-2xl text-sm text-gray-500">
                              upload: jpg,png,gif,webp
                            </span>

                            <div className="mt-2 mr-2">
                              <Switch
                                checked={isOutsourced}
                                //onChange={setIsOutsourced}
                                onChange={(event) => {
                                  setIsOutsourced(event);
                                  // Call your custom function here to update the initial value
                                  //updateInitialValue(event.target.checked);
                                  initialValues.mc_is_outsource = event;
                                  formState.mc_is_outsource = event;
                                  setFormState(formState);
                                  console.log("setIsOutsourced", event);
                                }}
                                className={classNames(
                                  isOutsourced ? "bg-cyan-600" : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    isOutsourced
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                              <p>is Outsourced?</p>
                            </div>
                          </div>
                        </div>

                        <div className="flex sm:border-t sm:border-gray-200 sm:pt-5 gap-5">
                          <div className="basis-1/3">
                            <label
                              htmlFor="account-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Account name
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_name"
                                id="mc_name"
                                autoComplete="account-name"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_name && touched.mc_name ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_name}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="basis-1/3">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Mobile Number
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_phone"
                                id="mc_phone"
                                autoComplete="phone"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_phone && touched.mc_phone ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_phone}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Email
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="email"
                                name="mc_email"
                                id="mc_email"
                                autoComplete="email"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_email && touched.mc_email ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_email}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className=" basis-1/3">
                            <label
                              htmlFor="credit-limit"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Address
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_address"
                                id="mc_address"
                                autoComplete="mc_address"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_address && touched.mc_address ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_address}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className=" basis-1/3">
                            <label
                              htmlFor="legel-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Postal Code
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_postalCode"
                                id="mc_postalCode"
                                autoComplete="mc_postalCode"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_postalCode && touched.mc_postalCode ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_postalCode}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="basis-1/3">
                            <label
                              htmlFor="invoice_vat_no"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Fax
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_fax"
                                id="mc_fax"
                                autoComplete="mc_fax"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_fax}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_fax && touched.mc_fax ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_fax}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="basis-1/3">
                            <label
                              htmlFor="mc_vat"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              VAT
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="number"
                                name="mc_vat"
                                id="mc_vat"
                                autoComplete="mc_vat"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_vat}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_vat && touched.mc_vat ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_vat}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <label
                              htmlFor="mc_oc_discount"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Discount
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="number"
                                name="mc_oc_discount"
                                id="mc_oc_discount"
                                autoComplete="mc_oc_discount"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_oc_discount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_oc_discount && touched.mc_oc_discount ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_oc_discount}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <label
                              htmlFor="mc_website"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Website
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_website"
                                id="mc_website"
                                autoComplete="mc_website"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_website}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_website && touched.mc_website ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_website}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className=" basis-1/3">
                            <label
                              htmlFor="mc_latitude"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Lattitude
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_latitude"
                                id="mc_latitude"
                                autoComplete="mc_latitude"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_latitude}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_latitude && touched.mc_latitude ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_latitude}
                                </p>
                              ) : null}{" "}
                            </div>
                          </div>
                          <div className=" basis-1/3">
                            <label
                              htmlFor="mc_longitude"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Longitude
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="mc_longitude"
                                id="mc_longitude"
                                autoComplete="mc_longitude"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                value={values.mc_longitude}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.mc_longitude && touched.mc_longitude ? (
                                <p className="text-red-600 text-sm">
                                  {errors.mc_longitude}
                                </p>
                              ) : null}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5">
                          <div className="basis-1/3">
                            <label
                              htmlFor="fk_country_id"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Country
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              {contryList.length > 0 && (
                                <select
                                  id="fk_country_id"
                                  name="fk_country_id"
                                  onChange={(e) => {
                                    handleChange(e);
                                    getStateListByCountry(e.target.value);
                                    values.fk_state_id = "";
                                    values.fk_city_id = "";
                                    setStateList([]);
                                    setCityList([]);
                                  }}
                                  value={values.fk_country_id}
                                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                                  // defaultValue={tabs.find((tab) => tab.current).name}
                                >
                                  <option disabled value="" selected>
                                    {" "}
                                    select country{" "}
                                  </option>
                                  {contryList.map((cont) => (
                                    <option
                                      key={cont.country_id}
                                      value={cont.country_id}
                                    >
                                      {cont.country_name}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {errors.fk_country_id && touched.fk_country_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fk_country_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <label
                              htmlFor="fk_state_id"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              State
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <select
                                disabled={values.fk_country_id ? false : true}
                                id="fk_state_id"
                                name="fk_state_id"
                                onChange={(e) => {
                                  handleChange(e);
                                  getCityListByState(e.target.value);
                                  values.fk_city_id = "";
                                  setCityList([]);
                                }}
                                value={values.fk_state_id}
                                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  select State{" "}
                                </option>
                                {stateList.map((cont) => (
                                  <option
                                    key={cont.fk_state_id}
                                    value={cont.state_id}
                                  >
                                    {cont.state_name_en}
                                  </option>
                                ))}
                              </select>
                              {errors.fk_state_id && touched.fk_state_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fk_state_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="basis-1/3">
                            <label
                              htmlFor="fk_city_id"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              City
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <select
                                disabled={values.fk_state_id ? false : true}
                                id="fk_city_id"
                                name="fk_city_id"
                                onChange={handleChange}
                                value={values.fk_city_id}
                                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  select City{" "}
                                </option>
                                {cityList.map((cont) => (
                                  <option
                                    key={cont.fk_city_id}
                                    value={cont.city_id}
                                  >
                                    {cont.city_name_en}
                                  </option>
                                ))}
                              </select>
                              {errors.fk_city_id && touched.fk_city_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fk_city_id}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="basis-1/3">
                            {/* PriceList DD */}
                            <div className="mt-2">
                              <div>
                                <label
                                  htmlFor="price_id"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Select Price List
                                </label>
                              </div>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <Select
                                  name="price_id"
                                  id="price_id"
                                  value={singlePriceListId}
                                  onChange={(e) => singlePriceList(e)}
                                  onBlur={handleBlur}
                                  options={priceList}
                                  className="basic-single-select"
                                  classNamePrefix="Select PriceList"
                                />
                              </div>
                            </div>
                          </div>

                          {/* Attach Location to Outsource Clinic  */}
                          { !isOutsourced ? (
                          <div className="basis-1/3">
                              <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Locations</label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <Select
                                      name="fk_location_id"
                                      id="fk_location_id"
                                      value={selectedLocation}
                                      onChange={(e) => setSelectedLocation(e)}
                                      options={l_List}
                                      classNamePrefix="Select Locations"
                                  />
                              </div>
                          </div>
                          ) : null}

                        </div>

                        {isOutsourced ? (
                          <div className="flex gap-3">
                            <div className="basis-1/3">
                              <label
                                htmlFor="mc_payment_type"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Payment Mode
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <select
                                  value={values.mc_payment_type}
                                  //onChange={handleChange}
                                  onChange={(e) => {
                                    setSelectedPM(e);
                                    values.mc_payment_type = e.target.value;
                                  }}
                                  onBlur={handleBlur}
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                  name="mc_payment_type"
                                  id="mc_payment_type"
                                >
                                  <option value="" selected disabled={true}>
                                    Select Type
                                  </option>
                                  {payment_type.map((data, i) => (
                                    <option
                                      selected={
                                        data === values.mc_payment_type
                                          ? "selected"
                                          : ""
                                      }
                                      key={i}
                                      value={data}
                                    >
                                      {data}
                                    </option>
                                  ))}
                                </select>
                                {errors.mc_payment_type &&
                                touched.mc_payment_type ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.mc_payment_type}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            {getPaymentMode && getPaymentMode === "POSTPAID" ? (
                              <div className="basis-1/3">
                                <label
                                  htmlFor="mc_interval"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                  Payment Interval
                                </label>
                                <select
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                  name="mc_interval"
                                  id="mc_interval"
                                  value={values.mc_interval}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option selected defaultValue={""}>
                                    {" "}
                                    Select Interval{" "}
                                  </option>
                                  {payment_interval?.map((data, i) => (
                                    <option
                                      selected={
                                        data === values.mc_interval
                                          ? "selected"
                                          : ""
                                      }
                                      key={i}
                                      value={data}
                                    >
                                      {data}
                                    </option>
                                  ))}
                                </select>

                                {errors.mc_interval && touched.mc_interval ? (
                                  <div className="text-sm text-red-600">
                                    {errors.mc_interval}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            <div className="basis-1/3">
                              <label
                                htmlFor="mc_limit"
                                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                              >
                                Credit Limit (Amount)
                              </label>
                              <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <input
                                  type="number"
                                  name="mc_credit_limit"
                                  id="mc_credit_limit"
                                  className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm ${Iid ? "text-gray-400" : "text-gray-800"}`}
                                  value={values.mc_credit_limit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={Iid}
                                />
                                {errors.mc_credit_limit &&
                                touched.mc_credit_limit ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.mc_credit_limit}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        

                        {/* <div className="flex flex-row mt-2  pt-5">
                          <div className="basis-3/4">
                            <label
                              htmlFor="cashStatus"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3"
                            >
                              {" "}
                              Cod
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Switch
                                checked={enabledCod}
                                onChange={setEnabledCod}
                                className={classNames(
                                  enabledCod ? "bg-cyan-600" : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    enabledCod
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>

                          <div className="basis-3/4">
                            <label
                              htmlFor="cashStatus"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3"
                            >
                              Prepaid
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Switch
                                checked={enabledPrepaid}
                                onChange={setEnabledPrepared}
                                className={classNames(
                                  enabledPrepaid
                                    ? "bg-cyan-600"
                                    : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    enabledPrepaid
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>

                          <div className="basis-3/4">
                            <label
                              htmlFor="branchStatus"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3"
                            >
                              Credit
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Switch
                                checked={enabledCredit}
                                onChange={setEnabledCredit}
                                className={classNames(
                                  enabledCredit ? "bg-cyan-600" : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    enabledCredit
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>

                          <div className="basis-3/4">
                            <label
                              htmlFor="primaryStatus"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-3"
                            >
                              Transfer
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <Switch
                                checked={enabledTransfer}
                                onChange={setEnabledTransfer}
                                className={classNames(
                                  enabledTransfer
                                    ? "bg-cyan-600"
                                    : "bg-gray-200",
                                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    enabledTransfer
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-end gap-x-3">
                        <button
                          type="button"
                          onClick={(e) => navigate("/outsourced-clinic")}
                          className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={loading}
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                        >
                          {Iid ? "Update" : "Create"} {loading && <FaSpinner
                                icon="spinner"
                                className="spinner ml-2"
                              />}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : null}
                {tab?.href === "#credit-details" && tab?.current ? (
                  <CreditList clinic_id={Iid}/>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddOutsourcedClinic;
