import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { reservation_trnx_columns } from "../../../components/tables/tableheader";
import Breadcrumb from "../../../components/Breadcrumb";
import ReservationServices from "../../../ApiServices/ReservationServices";
import queryString from "query-string";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import { toast } from "react-toast";
import { capitalise } from "../../../utilities/utilities";
import { convertToAnotherTimezone } from "../../../helper/commonHelper";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MasterServices from "../../../ApiServices/MasterServices";

const initialSalesChartFilters = {
  start_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  end_date: moment(new Date()).format("YYYY-MM-DD"),
  users: []
}
const ReservationTranxList = () => {
  const pages = [
    { title: "Resrvation List", href: "/reservationlist", module_id: 8 },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [resUserReportFilters, setResUserReportFilters] = useState(initialSalesChartFilters)
  const [resUserReportData, setResUserReportData] = useState([])
  // const [selectedUsers, setSelectedUsers] = useState(false)
  // const [userLoading, setUserLoading] = useState(true)
  //   const [userList, setUserList] = useState(false);
  const navigate = useNavigate();
  const printRef = React.useRef();

  useEffect(() => {
    document.title = "Labib | Resrvation List";
    getResUserReport()
  }, []);

  //   const getAllUsers = async () => {
  //     try {
  //         setUserLoading(true)
  //         const { data } = await ReservationServices.getAllUserList();
  //         if (data.status) {
  //             let newUsers = data.data;
  //             let users = newUsers.map((el) => {
  //                 let obj = { ...el, label: el.user_first_name, value: el.user_id }
  //                 return obj
  //             })
  //             setUserList(users);
  //         }
  //         setUserLoading(false)
  //     } catch (error) {
  //         console.log(error);
  //         setUserLoading(false)
  //     }
  // };

  //   const handleMultiUsers = (event) => {
  //     setSelectedUsers(event)
  //     let users = [];
  //     for (let i = 0; i < event.length; i++) {
  //         users.push(event[i].user_id)
  //     }
  //     setResUserReportFilters((prev) => {
  //         return {
  //             ...prev,
  //             users,
  //         }
  //     })
  // }
  async function getResUserReport() {
    setIsLoading(true)
    try {
      const { data, status } = await ReservationServices.getAllReservationFilterList(queryString.stringify({ isForReport: true, filterFlag: true, filterTo: resUserReportFilters.end_date, filterFrom: resUserReportFilters.start_date }));
      if (status === 200) {
        setResUserReportData(data.data.getAllReservation)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const generateObjectToPdf = async () => {
    // setIsLoading(true)
    try {
        let newSheetdata = [];
        if (resUserReportData?.length > 0) {
            resUserReportData.map((obj, index) => {
              const newData = {
                res_id: obj.reservation_id,
                user: obj.rev_patient_parent_fname,
                MobileNo: obj.rev_patient_parent_phone,
                gender: obj.rev_patient_parent_gender_name,
                country: obj.rev_patient_parent_country_name,
                Branch_Name: obj.loc_name_en,
                Referral_Clinic: obj.ot_clinic_details ? obj.ot_clinic_details.mc_name : obj.referral_clinic_details ? obj.referral_clinic_details.mc_name : "Self",
                Date: moment(convertToAnotherTimezone(obj.reservation_created_at)).format('DD-MM-YYYY'),
                time: moment(convertToAnotherTimezone(obj.reservation_created_at)).format('hh:mm:ss a'),
                payment_status: obj.is_payment_completed ? "Completed" : "Pending",
                Payment_Type: obj.getAllReservationPayment?.pay_mode_name_en ? obj.getAllReservationPayment?.pay_mode_name_en : "-",
                paid_amt: capitalise(parseFloat(obj.rev_payable_amount).toFixed(2)),
                Cash: parseFloat(obj.getAllReservationPayment?.payment_data?.cashAmt ? obj.getAllReservationPayment?.payment_data?.cashAmt : 0 ).toFixed(2),
                Card: parseFloat(obj.getAllReservationPayment?.payment_data?.visaAmt ? obj.getAllReservationPayment?.payment_data?.visaAmt : 0 ).toFixed(2),
                Bank_Transfer: parseFloat(obj.getAllReservationPayment?.payment_data?.bankTransAmt ? obj.getAllReservationPayment?.payment_data?.bankTransAmt : 0 ).toFixed(2),
                VAT: capitalise(parseFloat(obj.rev_vat_amount).toFixed(2)),
                received_by: capitalise(obj.paymentcollectedbyusername),
                status: obj.reservation_is_active == true ? 'Active' : 'Cancelled'
              }
              newSheetdata.push(newData);
            })
        }
        let payload = {
            columns: [
              'Reservation Id',
              'User',
              'Mobile No.',
              'Gender',
              'Country',
              'Branch Name',
              'Referral Clinic',
              'Date',
              'Time',
              'Payment Status',
              'Payment Type',
              'Payable Amount',
              'Cash',
              'Card',
              'Bank Transfer',
              'VAT',
              'Received By',
              'Status'
            ],
            pdf_name: 'Reservation_Transaction_Report_From_' + moment(resUserReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resUserReportFilters.end_date).format("DD-MM-YYYY"),
            rows: newSheetdata
    }
        const { data, status } = await MasterServices.generateObjectToPDF(payload);
        console.log(data)
        if (status === 200) {
            if(data?.data?.pdf_url != '') {
                // window.location.replace(data.pdf_url);
                window.open(data.data.pdf_url, '__blank');
            }
                // setResUserReportData(data.data)
        }
        // setIsLoading(false)
    } catch (error) {
        console.log(error)
        // setIsLoading(false)
    }
}

  const handleExport = (excel_type) => {
    if (resUserReportData?.length > 0) {
      let newSheetdata = [];
      resUserReportData.map((obj, index) => {
        const newData = {
          res_id: obj.reservation_id,
          user: obj.rev_patient_parent_fname,
          MobileNo: obj.rev_patient_parent_phone,
          gender: obj.rev_patient_parent_gender_name,
          country: obj.rev_patient_parent_country_name,
          Date: moment(convertToAnotherTimezone(obj.reservation_created_at)).format('DD-MM-YYYY'),
          time: moment(convertToAnotherTimezone(obj.reservation_created_at)).format('hh:mm:ss a'),
          payment_status: obj.is_payment_completed ? "Completed" : "Pending",
          paid_amt: capitalise(parseFloat(obj.rev_payable_amount).toFixed(2)),
          VAT: capitalise(parseFloat(obj.rev_vat_amount).toFixed(2)),
          received_by: capitalise(obj.paymentcollectedbyusername),
          status: obj.reservation_is_active == true ? 'Active' : 'Cancelled'
        }
        newSheetdata.push(newData);
      })
      let fileName = 'Reservation_Transaction_Report_From_' + moment(resUserReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resUserReportFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;

      const headings = [
        ['Reservation Id',
          'User',
          'Mobile No.',
          'Gender',
          'Country',
          'Date',
          'Time',
          'Payment Status',
          'Paid Amount',
          'VAT',
          'Received By',
          'Status'
        ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  const handlePdfGenerate = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF(
      'landscape',
      // 'mm',
      // 'a4'
    );
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfWidth = 210
    
    
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    let fileName = 'Reservation_Report_From_' + moment(resUserReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resUserReportFilters.end_date).format("DD-MM-YYYY") + '.pdf';

    pdf.save(fileName);
  }


  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <h1 className="text-xl font-semibold text-gray-900">
            Reservations List
          </h1>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="mt-5 flex gap-3 items-center">
          {/* start date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date From
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={resUserReportFilters.start_date}
              onChange={(e) => {
                setResUserReportFilters((prev) => {
                  if (prev.end_date < e.target.value) {
                    return {
                      ...prev,
                      end_date: e.target.value,
                      start_date: prev.end_date,
                    };
                  }
                  return {
                    ...prev,
                    start_date: e.target.value,
                  };
                });
              }}
            />
          </div>

          {/* end date */}
          <div className=" sm:mt-0">
            <label
              htmlFor="fk_location_ids"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Date To
            </label>

            <input
              disabled={isLoading}
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={resUserReportFilters.end_date}
              onChange={(e) => {
                setResUserReportFilters((prev) => {
                  if (prev.start_date > e.target.value) {
                    return {
                      ...prev,
                      end_date: prev.start_date,
                      start_date: e.target.value,
                    };
                  }
                  return {
                    ...prev,
                    end_date: e.target.value,
                  };
                });
              }}
            />
          </div>
          {/* 
          {!userLoading ? (
          <div className="">
            <label
              htmlFor="fk_location_ids"
              className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Users
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                // defaultValue={testList[0]}
                isMulti
                name="fk_user_ids"
                id="fk_user_ids"
                value={selectedUsers}
                onChange={(e) => handleMultiUsers(e)}
                options={userList}
                className="basic-multi-select"
                classNamePrefix="Select Test"
              />
            </div>
          </div>
           ) : null}  */}

          <button
            disabled={isLoading}
            onClick={() => {
              getResUserReport();
            }}
            className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"
              }`}
          >
            Get Data
          </button>
        </div>

        <div className="mt-5 flex gap-1 items-center">
          <button
            onClick={() => handleExport("csv")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            CSV
          </button>

          <button
            onClick={() => handleExport("xlsx")}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            Excel
          </button>

          <button
            onClick={() => generateObjectToPdf()}
            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
            PDF
          </button>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>

            {isLoading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                zoom='zoom-065'
                printRef={printRef}
                columns={reservation_trnx_columns()}
                data={resUserReportData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationTranxList;
