import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { reference_range_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { Dialog, Transition, } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils } from 'xlsx';

function ReferenceList() {
  const pages = [{ title: "Reference Rnages List", href: "/reference-ranges", module_id: 8 }];
  const [loading, setLoading] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mrr_id: null,
    mrr_delete: false,
  });
  const [referenceRangeList, setReferenceRangeList] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    getReferenceRangeList();
    document.title = "New Labib | Reference Ranges";
    console.log("Reference Ranges=>", referenceRangeList)
  }, []);

  const getReferenceRangeList = async () => {
    MasterServices.getAllReferenceRange()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setReferenceRangeList(data.data);
          } else {
            toast.error("Reference Ranges Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "Error in Reference Ranges";
        toast.error(msg)
        setLoading(false);
      });

  }
 
  const onDeleteOpen = (mrr_id, mrr_delete) => {
    setConfirmationModal({ mrr_id, mrr_delete, status: true });
  };

  const onDeleteReferenceRange = (mrr_id, mrr_delete) => {
    let Newmrr_delete = false;
    Newmrr_delete = mrr_delete == true ? false : true;
    let text = "Active";
    if (Newmrr_delete == true) {
      text = "Deleted";
    } else {
      text = "Active";
    }
    let paylaod = {
      mrr_id:mrr_id,
      mrr_delete:Newmrr_delete,
    }
    MasterServices.removeReferenceRange(paylaod)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Refrance Range " + text + " Successfully!")
          setConfirmationModal({ mrr_id: null, status: false });
          getReferenceRangeList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ mrr_id: null, status: false });
  };

  useEffect(() => {
    console.log("getReferenceRangeList =>", referenceRangeList)
    getReferenceRangeList();
  }, []); //referenceRangeList


  const importReferenceRangeAPICall = (data) => {
    const payload = { excel_data: data }
    console.log("importReferenceRangeAPICall", payload);
    // return false;
    MasterServices.importReferenceRange(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("Imported reference range data:", data)
          toast.success("Reference Ranges Imported Successfully");
        } else {
          toast.error(data.message)
        }
        getReferenceRangeList();
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }


  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log("handleImport in RR: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.name_english != undefined) {
                const newObj = {
                  mrr_name_en: obj.name_english,
                  mrr_name_ar: obj.name_arabic ? obj.name_arabic : obj.name_english,
                  mrr_default_min: obj.default_normal_min,
                  mrr_symbol: obj.symbol,
                  mrr_default_max: obj.default_normal_max,

                  mrr_unit: obj.unit,
                  mrr_formula: obj.formula,

                  // Critical
                  mmr_cls: obj.critical_low_symbol,
                  mmr_clv: obj.citical_low_value,
                  mmr_chs: obj.critical_high_symbol,
                  mmr_chv: obj.critical_high_value,


                  // Impossible
                  mmr_ils: obj.impossible_low_symbol,
                  mmr_ilv: obj.impossible_low_value,
                  mmr_ihs: obj.impossible_high_symbol,
                  mmr_ihv: obj.impossible_high_value,

                  // Reference Comment
                  mrr_comments: obj.reference_comments,

                  // mmr_inputs_value: input_json?.length > 0 ? input_json : [], inputs_value
                };
                newData.push(newObj);
              }
            });
            // console.log("newArray: ", newData);
            // const filteredArray = newData.filter(obj => obj.fk_rr_id !== undefined && obj.fk_test_id !== undefined);
            // console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              // console.log("newData", newData)
              importReferenceRangeAPICall(newData);
            }
          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure want to " + (confirmationModal.mrr_delete ? "Activate? " : "Remove?") + " Referance Range"}
        confirmationButtonText={confirmationModal.mrr_delete ? "Activate" : "Remove"}
        description={"Do you really want to " + (confirmationModal.mrr_delete ? "Activate" : "Remove") + " Referance Range"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteReferenceRange(confirmationModal.mrr_id, confirmationModal.mrr_delete)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Reference Ranges</h1>
      </div>
      <div className="mt-4 flex">
        <input type="file"
          onChange={handleImport}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          placeholder="Import File"
        />

        <Link
          to="/add-reference-ranges"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Reference
        </Link>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((referenceRangeList.length > 0) ? (
          <Table
          zoom='zoom-075'
            columns={reference_range_columns({ onDeleteOpen })}
            data={referenceRangeList}
          />
        ) : <TableEmpty />)
      }
    </div >
  );
}
export default ReferenceList;