import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { questionnaire_columns } from '../../../components/tables/tableheader';
import { getAllQuestionnaires } from '../../../redux/questionnaire/actions';

const QuestionnaireList = () => {
    const pages = [{ title: "Questionnaire List", href: "/questionnaire-list", module_id: 18 }];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        loading,
        questionnaire: { list: questionnaireList }
    } = useSelector((state) => state);

    useEffect(() => {
        (async function () {
            await dispatch(await getAllQuestionnaires());
        })()
    }, [])

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Questionnaire List</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Questionnaire List.</p>
            </div>
            <div className="mt-4 flex">
                <Link
                    to={`/questionnaire`}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add Form
                </Link>
            </div>
            {
                loading ? (
                    <FallingLinesLoader />
                ) : ((questionnaireList.length > 0) ? (
                    <Table
                        columns={questionnaire_columns()}
                        data={questionnaireList}
                    />
                ) : null)
            }
        </div>
    )
}

export default QuestionnaireList