import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { priceListSchema } from "../../../schemas";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import TableEmpty from "../../../components/tables/tableEmpty";
import { price_list_columns } from "../../../components/tables/tableheader";
import { deletePrice, getAllPriceList, addPrice, updatePrice } from "../../../redux/price_list/actions";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils, writeFile } from 'xlsx';

const typeList = ["Sales", "Contract"];

function PriceList() {
  const initialValues = { price_id: "", price_name_ar: "", price_name_en: "", price_type: "" };
  const pages = [{ title: "Price List", href: "/price-list", module_id: 21 }];

  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(true)
  const [price_id, setPriceListId] = useState('')
  const [formState, setFormState] = useState(initialValues);
  const [priceList, setPriceList] = useState(false)
  //const [confirmationModal, setConfirmationModal] = useState({ status: false, _id: null});
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    price_id: null,
    price_is_active: false,
  });

  const [openDuplicatePL, setOpenDuplicatePL] = useState(false)
  const [selectedPL, setSelectedPL] = useState(false)
  const [duplicatePLName, setDuplicatePLName] = useState("")

  useEffect(() => {
    document.title = "Labib | Price List";
    getAllPriceList();
  }, []);

  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setPriceListId(id)
      const initialValues = {
        price_id: obj.price_id,
        price_name_en: obj.price_name_en,
        price_name_ar: obj.price_name_ar,
        price_type: obj.price_type
      };
      setFormState(initialValues);
    } else {
      setPriceListId("")
      setFormState(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const priceListDetails = (name, obj) => {
    console.log("priceListDetails : ", { name, obj });
  }

  const onDeleteOpen = (price_id, price_is_active) => {
    setConfirmationModal({ price_id, price_is_active, status: true });
  };

  const onDeletePrice = (price_id, price_is_active) => {
    //dispatch(deletePrice(price_id));
    //setConfirmationModal({ price_id: null, status: false });

    let check_is_active = false;
    check_is_active = price_is_active == true ? false : true;
    let text = "Activated";
    if (check_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setPriceListStatus({ price_id, price_is_active: check_is_active })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("PriceList " + text + " Successfully!")
          setConfirmationModal({ price_id: null, status: false });
          getAllPriceList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "setPriceListStatus Catch Error"; //error.response.data.message;
        toast.error(msg)
      });

  };

  const getAllPriceList = () => {
    MasterServices.getPriceList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setPriceList(data.data);
          } else {
            toast.error("PriceList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllPriceList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          // console.log("sheets.length", sheets.length)
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log("Test handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            // let count = 0
            rows.map((obj, index) => {
              let {
                tp_price, 
                tp_type, 
                tp_visit_type, 
                fk_test_id, 
                fk_package_id, 
                fk_price_id,
                m_price_item_is_active,
                m_price_item_is_deleted,
                tp_selling_price,
                price_item_id
              } = obj

              if (obj && fk_price_id && tp_price && (fk_test_id || fk_package_id) && !price_item_id) {
                // console.log(obj, fk_package_id, fk_test_id, fk_price_id)
                // console.log("count", count)
                // count++
                const newObj = {
                  tp_price: tp_price,
                  tp_selling_price: tp_selling_price,
                  tp_type: tp_type?.trim() == 'T' || tp_type?.trim() == 'P' ? tp_type.trim() : null,
                  tp_visit_type: tp_visit_type.trim() == 'N' || tp_visit_type.trim() == 'H' ? tp_visit_type.trim() : null,
                  
                  fk_test_id: fk_test_id ? parseInt(fk_test_id) : null,
                  fk_package_id: fk_package_id ? parseInt(fk_package_id) : null,
                  fk_price_id: fk_price_id ? parseInt(fk_price_id) : null,
                                    
                  m_price_item_is_active,
                  m_price_item_is_deleted
                };

                newData.push(newObj);
              }

            });

            if (newData.length > 0) {
              importPriceListItems(newData);
            }

          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importPriceListItems = (data) => {
    const payload = { excel_data: data }
    setLoading(true)
    MasterServices.importPriceListItems(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setLoading(false)
          toast.success("Price List Items Imported Successfully");
        } else {
          setLoading(false)
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        setLoading(false)
        toast.error(msg)
      });
  }

  const showDuplicatePL = (data) =>{
    // console.log(data)
    setSelectedPL(data)
    setOpenDuplicatePL(true)
  }

  const closeModal = () => {
    setOpenDuplicatePL(false);
    setDuplicatePLName("")
		setSelectedPL(false)
	}

  const handleDuplicatePL = () =>{
    const payload = {
      new_pl_name : duplicatePLName ? duplicatePLName : false,
      ref_pl : selectedPL ? selectedPL : false
    }
    setLoading(true)
    console.log("payload handleDupPL", payload)

    MasterServices.duplicatePriceList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
          setLoading(false)
          toast.success("Price List Duplicated Successfully");
          closeModal()
          getAllPriceList()
        } else {
          setLoading(false)
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while dupicating";
        setLoading(false)
        toast.error(msg)
      });
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: priceListSchema,
      onSubmit: (values, action) => {
        let body = {
          price_id: price_id,
          price_name_en: values.price_name_en,
          price_name_ar: values.price_name_ar,
          price_type: values.price_type
        }
        if (price_id === undefined || price_id === null || price_id === "") { } else {
          body.price_id = price_id;
        }

        MasterServices.savePriceList(body)
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              if (price_id != 0 || price_id != '') {
                toast.success("PriceList Updated Successfully");
              } else {
                toast.success("PriceList Added Successfully");
              }
              getAllPriceList();
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          })
          .catch((error) => {
            const msg = "savePriceList Catch Error" //error.response.data.message;
            toast.error(msg)
          });

        action.resetForm();
        if (modalOpenFlage === true) {

          setmodalOpenFlage(false);
        };

      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={
          confirmationModal.price_is_active
            ? "Are you sure you want to Disable? "
            : "Are you sure you want to Activate?"
        }
        confirmationButtonText="Yes"
        // description={confirmationModal.price_is_active ? "Do you really want to In-Activate? " : "Do you really want to Activate?"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() =>
          onDeletePrice(
            confirmationModal.price_id,
            confirmationModal.price_is_active
          )
        }
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Price List</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Prices.</p>
      </div>
      <div className="mt-4 flex">
        {JSON.parse(localStorage.getItem("user"))?.user_email ==
        "superadmin@tabiib.com" ? (
          <input
            type="file"
            onChange={handleImport}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            placeholder="Import File"
          />
        ) : null}

        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Price List
        </Link>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : priceList.length > 0 ? (
        <Table
          columns={price_list_columns({
            onDeleteOpen,
            handleDrawer,
            priceListDetails,
            showDuplicatePL,
          })}
          data={priceList}
        />
      ) : (
        <TableEmpty />
      )}
      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={handleSubmit}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 p-4 sm:p-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {price_id ? "Update" : "Add"} Price List
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Enter Price List Name (EN)
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.price_name_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Price Name"
                                    name="price_name_en"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.price_name_en &&
                                  touched.price_name_en ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.price_name_en}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Enter Price List Name (AR)
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.price_name_ar}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Price Name"
                                      name="price_name_ar"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.price_name_ar &&
                                    touched.price_name_ar ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.price_name_ar}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Enter Price List Type
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <select
                                      value={values.price_type}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="price_type"
                                      id="price_type"
                                    >
                                      <option>Select Type</option>
                                      {typeList.map((name, i) => (
                                        <option
                                          selected={
                                            name === values.price_type
                                              ? "selected"
                                              : ""
                                          }
                                          key={i}
                                          value={name}
                                        >
                                          {name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.price_type && touched.price_type ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.price_type}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {price_id ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <div>
        <Transition.Root show={openDuplicatePL} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={() => closeModal()}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                      <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                        <div>Duplicate Price List from {selectedPL.price_name_en}</div>
                        <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                          <input
                            value={duplicatePLName}
                            onChange={(e) => {
                              setDuplicatePLName(e.target.value);
                            }}
                            type="text"
                            placeholder="Enter New Price List Name"
                            name="dpl_name"
                            autoComplete="off"
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>
                        <div className="flex justify-end">
                        <button
                            onClick={handleDuplicatePL}
                            disabled={!duplicatePLName?.length > 0}
                            className={`${duplicatePLName?.length ? "hover:bg-cyan-700 bg-cyan-600 text-white focus:ring-cyan-500" : "bg-gray-300 text-gray-500"} ml-4 inline-flex rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                          >
                            Duplicate
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      ;
    </div>
  );
}

export default PriceList;